import { Card, IconButton } from "@mui/material";
import styled, { css } from "styled-components";

interface ITabProps {
  selected: Boolean;
}

export const Container = styled.div`
  width: 100%;
  max-width: 90%;
  min-height: 100%;
  margin: 0px auto;
`;

export const ListContainer = styled.div`
  border: 0;
  margin-top: 40px;
  gap: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
`;

export const ContactsTabs = styled.div`
  display: flex;

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 20px;
  }

  li {
    width: 150px;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid blue;
  }
`;

export const TabContainer = styled.div`
  margin: 25px 0px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const TabButton = styled.button<ITabProps>`
  width: 140px;
  border: 0;
  color: #444;
  border-radius: 4px;
  height: 30px;

  ${(props) =>
    props.selected &&
    css`
      color: white;
      background: ${props.theme.primary.light};
    `}
`;

// export const Content = styled.div`
//     min-width: 100%;
//     margin-bottom: 20;
//     margin-top: 20;
//     width: 100%;
//     display: flex;
//     flexDirection: row;
//     flexWrap: wrap;
//     gap: 10;
//     padding: 25;
//     height: 100%;
//     alignItems: center;
//     justifyContent: space-between;

// `;
