import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  //CONTAINER PRINCIPAL
  listContainer: {
    padding: 15,
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 15,
  },

  backButton: {
    width: "fit-content",
    color: "#777",
    cursor: "pointer",

    "& svg": {
      marginRight: 5,
      verticalAlign: "middle",
      fontSize: 20,
    },

    "&:hover": {
      transition: "1s",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    width: "100%",
    justifyContent: "space-between",
  },

  //STEP1

  //CONTA BC

  searchContact: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
    flexWrap: "wrap",
    gap: 30,
  },

  bcTransferPreSearch: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& img": {
      width: "50%",
    },
  },
  contactData: {
    padding: 20,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  //OUTRO BANCO
  otherBankContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
  },
  form: {
    width: "50%",
    margin: "0 auto",
    marginTop: 40,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },

  //FINAL STEP
  greeting: {
    fontSize: "2.125rem",
    fontWeight: 400,
    lineHeight: 1.235,
    verticalAlign: "sub",
    textAlign: "center",
    letterSpacing: "0.00735em",
    paddingBottom: 20,
    margin: "20px 0px",
  },
}));
