import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: sans-serif;

  /* iframe {
    width: 100%;
    height: 100%;
    border: 0;
  } */
  div {
    margin: 0 auto;
    margin: 20px;
    padding-right: 5px;
    overflow-y: auto;

    p,
    span {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
    }

    h1 {
      font-size: 24px;
      font-weight: 700;
    }

    h2 {
      font-size: 20px;
      font-weight: 500;
    }

    b {
      margin-left: 5px;
    }

    li::marker {
      font-weight: 700;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  margin: 15px;
  width: 100%;
  font-size: 10px;
  justify-content: center;
  align-items: center;
`;
