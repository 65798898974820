import useCallAxios from "./useCallAxios";

export const useConsultBarCodeData = () => {
  const filterQuery = (values) => ({ ...values });

  const context = `consultBarcode`;
  const {
    requestSuccess,
    requestData,
    requestLoading,
    fetchGET,
    requestError,
  } = useCallAxios({
    context,
  });

  const handleGET = (params) => {
    fetchGET({ context, queryParameters: filterQuery(params) });
  };

  return {
    loading: requestLoading,
    state: requestData.data,
    success: requestSuccess,
    handleGET,
    error: requestError,
  };
};
