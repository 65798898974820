import { Paper, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import intl from "react-intl-universal";
import { Loading } from "../../../Components/Atoms";
import {
  DateFilter,
  ReceiptList,
  ScreenGreeting,
} from "../../../Components/Molecules";
import { TableFilter } from "../../../Components/Molecules/Filters";
import { useTransactionsData } from "../../../Hooks";
import { useFilter } from "../../../Hooks/useFilter";
import {
  Container,
  ContentContainer,
  FilterContainer,
  ListContainer,
  PaperHeader,
} from "./styles";

export const Extract = () => {
  const matches = useMediaQuery("(min-width: 1220px)");
  const [page, setPage] = useState(1);

  const {
    handleGET: fetchTransactionsGET,
    loading,
    state: transactionsData,
  } = useTransactionsData();

  const { getFilterObj } = useFilter();
  const extractFilterObj = getFilterObj("extract");

  const handleSetToFirstPage = () => {
    setPage(1);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const queryParameters = {
      is_paged_result: true,
      page: page,
      page_size: 15,
      type: "all",
      ...extractFilterObj,
    };

    fetchTransactionsGET({ ...queryParameters });
  }, [extractFilterObj, page]);

  return (
    <Container>
      <ScreenGreeting
        title={intl.get(`phrases.myExtract`)}
        subTitle={intl.get(`phrases.allTransactionsForAccount`)}
      />
      <FilterContainer>
        <TableFilter
          tableName="extract"
          setPageToFirst={handleSetToFirstPage}
        />
      </FilterContainer>
      <Paper elevation={3}>
        <ContentContainer>
          <PaperHeader
            style={{
              flexDirection: matches ? "row" : "column",
            }}
          >
            {transactionsData !== undefined && (
              <DateFilter
                tableName={"extract"}
                setPageToFirst={handleSetToFirstPage}
                hasData={Object.keys(transactionsData).length > 0}
              />
            )}
          </PaperHeader>
          {loading.GET ? (
            <Loading />
          ) : (
            <ListContainer>
              <ReceiptList
                itens={transactionsData}
                loading={loading.GET}
                type="extract"
                title=""
                headerLink={""}
                hasFilter={""}
                handleChangePage={handleChangePage}
                page={page}
              />
            </ListContainer>
          )}
        </ContentContainer>
      </Paper>
    </Container>
  );
};
