const downloadFile = (filename, res, fileType) => {
  // let type = res.headers.get('Content-Type');
  let type = (fileType = "excel" ? "application/vnd.ms-excel" : "text/csv");
  let blob = new Blob([res.data], { type });
  let URL = window.URL || window.webkitURL;
  let downloadUrl = URL.createObjectURL(blob);
  if (filename) {
    let a = document.createElement("a");
    if (typeof a.download !== "undefined") {
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      return;
    }
  }

  window.location = downloadUrl;
};

export default downloadFile;
