export const formatInputValue = (value: string) => {
  const onlyNumbers = value.replace(/[^0-9]/g, "");
  let formattedValue;
  onlyNumbers !== "00"
    ? (formattedValue = Intl.NumberFormat("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(onlyNumbers) / 100))
    : (formattedValue = "");

  return formattedValue;
};

export const maskToDecimal = (value: number) => {
  if (!value) {
    return 0.0;
  }
  let newValue = formatInputValue(String(value));

  newValue = newValue.replace(/\./g, "");
  newValue = newValue.replace(/,/g, ".");
  newValue = newValue.replace(/[^0-9.]/g, "");

  return newValue;
};
