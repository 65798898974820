import { CircularProgress } from "@mui/material";
import { Container } from "./styles";

export const Loading = (props) => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};
