import { useState } from "react";
import { TableCell, Collapse, TableRow } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import { format } from "date-fns";
import {
  CollapseContainer,
  CollapseContent,
  CustomTableRow,
  ExpandMoreButton,
  IDsContainer,
  StatusTableCell,
  StyledH2,
} from "../styles";
import { formatCurrency } from "../../../../Utils/formatters";
import { handleTaxIDInput } from "../../../../Utils";
import { statusPropertyConverted } from "../../../../Utils/checkObjectProperties";

interface RowProps {
  transaction: any;
  tableName: string;
}

export const Row = ({ transaction, tableName }: RowProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const translateStatus = (value: string) => {
    switch (value) {
      case "paid":
        return "Pago";
      case "canceled":
        return "Cancelado";
      case "pending":
        return "Pendente";
      case "processing":
        return "Processando";
      case "rejected":
        return "Rejeitado";
      case "refused":
        return "Recusado";
      case "refunded":
        return "Ressarcido";
      case "expired":
        return "Expirado";
      case null:
        return "Expirado";

      default:
        "Pendente";
    }
  };

  const checkIfValueIsNull = (value: string | null) => {
    if (value === null || value === undefined) {
      return "-";
    }

    return value;
  };

  return (
    <>
      <CustomTableRow>
        <TableCell>
          <ExpandMoreButton
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Mostrar últimas movimentações"
            title="Mostrar últimas movimentações"
          >
            <ExpandMore />
          </ExpandMoreButton>
        </TableCell>
        <TableCell>{checkIfValueIsNull(transaction.id)}</TableCell>
        <TableCell scope="row">
          {checkIfValueIsNull(transaction.end_to_end_id)}
        </TableCell>
        <TableCell>
          <StatusTableCell status={statusPropertyConverted(transaction.status)}>
            {translateStatus(transaction.status)}
          </StatusTableCell>
        </TableCell>
        <TableCell>{formatCurrency(transaction.amount)}</TableCell>
        <TableCell style={{ width: "10%" }}>
          {transaction !== null
            ? format(
                Date.parse(transaction.created_at),
                "dd/MM/yyyy 'às' HH:mm"
              )
            : "//"}
        </TableCell>
      </CustomTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CollapseContainer>
              <StyledH2>Detalhes</StyledH2>

              <CollapseContent>
                <div>
                  <p>
                    {tableName === "ReceivedTable" ? (
                      <>pagador </>
                    ) : (
                      <>recebedor </>
                    )}
                    <span>{checkIfValueIsNull(transaction.payer.name)}</span>{" "}
                  </p>
                  <p>
                    documento
                    <span>
                      {handleTaxIDInput(
                        checkIfValueIsNull(transaction.payer.document)
                      )}
                    </span>{" "}
                  </p>
                </div>
                <IDsContainer>
                  <p>
                    qrcode{" "}
                    <span>{checkIfValueIsNull(transaction.qrcode)} </span>
                  </p>
                  <p>
                    external ID{" "}
                    <span>{checkIfValueIsNull(transaction.external_id)}</span>{" "}
                  </p>
                </IDsContainer>
              </CollapseContent>
            </CollapseContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
