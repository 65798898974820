export const cnpjMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const cpfMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2");
};

export const receiptChargeMask =
  "99999999999-9 99999999999-9 99999999999-9 99999999999-9";

export const receiptBankMask =
  "99999.99999 99999.999999 99999.999999 9 99999999999999";

export const cepMask = (value) => {
  return value.replace(/\D/g, "").replace(/(\d{5})(\d)/, "$1-$2");
};

export const cleanTaxIDMask = (value) => {
  return value?.replace(/\./g, "")?.replace("-", "")?.replace("/", "");
};

export const phoneMask = (value) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d)/g, "($1)$2");
  value = value.replace(/(\d{5})(\d)/, "$1-$2");
  return value;
};

export const cleanPhoneMask = (value) => {
  value = value?.replace("(", "");
  value = value?.replace(")", "");
  value = value?.replace("-", "");
  return value;
};

export const handleTaxIDInput = (value) => {
  if (value === "-") {
    return value;
  }

  let taxID = cleanTaxIDMask(value);
  if (taxID.length >= 14) {
    taxID = cnpjMask(taxID);
  } else {
    taxID = cpfMask(taxID);
  }
  return taxID;
};
