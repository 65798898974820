import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import moment from "moment";
import { useTheme } from "styled-components";
import { formatCurrency } from "../../../Utils/formatters";
import { Loading } from "../../Atoms";
import { ModalLoader } from "../../Atoms/ModalLoader";

export const TransfersList = ({ itens, loading }) => {
  const theme = useTheme();
  const handleClickItem = (item) => {
    setViewReceiptDetails(true);
    setSelectedItem(item);
  };

  const handleCloseDetails = () => {
    setViewReceiptDetails(false);
    setSelectedItem({});
  };

  if (loading) {
    return <ModalLoader open={loading} text={"Carregando informações"} />;
  }
  return (
    <>
      <List
        dense
        style={{
          paddingBottom: 0,
          borderRadius: 5,
          height: "100%",
        }}
      >
        {itens?.length === 0 ? (
          <Box component="span" display="flex" justifyContent="center" m={1}>
            Nenhum resultado encontrado.
          </Box>
        ) : (
          itens?.map((el, index) => {
            return (
              <ListItem
                key={index.toString()}
                style={{
                  borderBottom: "1px solid lightGray",
                  display: "flex",
                  padding: 15,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <ListItemIcon
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    padding: 0,
                  }}
                >
                  <Money></Money>
                </ListItemIcon> */}
                <ListItemText
                  style={{ width: "100%" }}
                  primary={
                    <>
                      <Typography
                        variant="body1"
                        style={{
                          color: `${theme.primary.original}`,
                          fontWeight: 700,
                        }}
                      >
                        Para: {el?.receiver.name}
                      </Typography>
                      <div>
                        <Typography variant="body3">
                          {el?.description}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="body3">
                          {moment(el?.created_at).format(
                            `DD/MM/YYYY [às] HH:mm`
                          )}
                        </Typography>
                      </div>
                    </>
                  }
                />
                <ListItemText
                  style={{ width: "100%" }}
                  secondary={
                    el?.receiver.bank === "000" ? (
                      <>
                        <Typography variant="body2">
                          Entre contas {el?.receiver.bank_name}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="body2">{`Agência: ${el?.receiver.branch}`}</Typography>
                        <Typography variant="body2">{`Conta: ${el?.receiver.account_number}-${el?.receiver.account_digit}`}</Typography>
                        <Typography variant="body2">{`Banco: ${el?.receiver.bank_name}`}</Typography>
                      </>
                    )
                  }
                />
                <ListItemSecondaryAction
                  style={{ textAlign: "right", width: "100%" }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      color: `${theme.primary.original}`,
                      fontWeight: 700,
                    }}
                  >
                    {formatCurrency(el?.amount)}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        )}
      </List>
    </>
  );
};

TransfersList.propTypes = {};
