import { useEffect, useState } from "react";
import { Paper, useMediaQuery } from "@mui/material";
import {
  DateFilter,
  ScreenGreeting,
  TransfersList,
  TransferStepper,
} from "../../../Components/Molecules";
import intl from "react-intl-universal";
import { useTransfersData } from "../../../Hooks";
import {
  Container,
  ContentContainer,
  ListContainer,
  PaperHeader,
  PaperDiv,
  FilterContainer,
} from "./styles";
import qs from "qs";
import { useHistory } from "react-router-dom";
import { useFilter } from "../../../Hooks/useFilter";
import { TableFilter } from "../../../Components/Molecules/Filters";

export const Transfers = () => {
  const matches = useMediaQuery("(min-width: 1220px)");
  const history = useHistory();
  const parsedQS = qs.parse(location.search.replace("?", ""));
  const [page, setPage] = useState(1);
  const [addTransferStatus, setAddTransferStatus] = useState(false);
  const {
    state: transfersData,
    handleGET: fetchTransfersGET,
    loading,
  } = useTransfersData();
  // const { state: contactsData, handleGET: fetchContactsGET } =
  //   useContactsData();

  const { getFilterObj } = useFilter();
  const transfersFilterObj = getFilterObj("transfers");

  const handleSetToFirstPage = () => {
    setPage(1);
  };

  useEffect(() => {
    const queryParameters = {
      is_paged_result: true,
      page: page,
      page_size: 15,

      ...transfersFilterObj,
    };
    fetchTransfersGET({ ...queryParameters });
  }, [transfersFilterObj, page]);

  useEffect(() => {
    Boolean(parsedQS.newTransfer) === true && setAddTransferStatus(true);
  }, [parsedQS]);

  return (
    <Container>
      {!addTransferStatus ? (
        <>
          <ScreenGreeting
            title={intl.get(`words.transfers`)}
            subTitle={intl.get(`phrases.transfersList`)}
            hasButton={true}
            buttonTitle={intl.get(`phrases.newTransfer`)}
            handleClick={() => setAddTransferStatus(true)}
          />

          <FilterContainer>
            <TableFilter
              tableName="transfers"
              setPageToFirst={handleSetToFirstPage}
            />
          </FilterContainer>

          <Paper elevation={3}>
            <ContentContainer>
              <PaperHeader
                style={{
                  flexDirection: matches ? "row" : "column",
                }}
              >
                {transfersData !== undefined && (
                  <DateFilter
                    tableName={"transfers"}
                    setPageToFirst={handleSetToFirstPage}
                    hasData={Object.keys(transfersData).length > 0}
                  />
                )}
              </PaperHeader>
              <ListContainer>
                <TransfersList itens={transfersData} loading={loading.GET} />
              </ListContainer>
            </ContentContainer>
          </Paper>
        </>
      ) : (
        <>
          <PaperDiv>
            <TransferStepper
              onClose={() => {
                history.push("/transferencia");
                setAddTransferStatus(false);
              }}
            />
          </PaperDiv>
        </>
      )}
    </Container>
  );
};
