import { Button } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  height: auto;

  p {
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.primary.light};
      transition: 0.4s;
    }

    svg {
      margin-right: 10px;
      vertical-align: middle;
    }
  }
`;

export const DaysContainer = styled.div`
  margin: 25px 0px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const DateButton = styled.button`
  width: 100px;
  border: 0;
  color: #444;
  border-radius: 4px;
  height: 30px;
`;

export const FilterContent = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  color: #444;

  form {
    flex-wrap: wrap;
    gap: 10px;
    display: flex;
  }
`;

export const ReportButton = styled(Button)`
  && {
    font-size: 1rem;
    background: #b79568;
    border-color: #b79568 !important;
    text-transform: none;

    &:hover,
    &:focus {
      background-color: #a78568;
      filter: brightness(0.9);
    }
  }
`;
