import { Button, CompanyLogo } from "../../../Components/Atoms";
import { Container, Content } from "./styles";
// import MetaDecorator from "../../components/MetaDecorator";

export const MobilePage = ({ appUrl, title }) => {
  return (
    <Container>
      <Content>
        <div>
          <CompanyLogo width={250} height={100} />
          <p>Olá, você está em sua conta {title}</p>
          <span>
            Para sua melhor experiência, baixe o aplicativo e acesse o app de
            sua conta.
          </span>
          <Button onClick={() => window.open(appUrl)}>
            Baixar App da conta
          </Button>
        </div>
      </Content>
    </Container>
  );
};

export default MobilePage;
