import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  TextareaHTMLAttributes,
} from "react";
import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";
import { Container, Content, Error, Label } from "./styles";

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  type?: string;
  width?: string;
  height?: string;
  placeholder?: string;
  containerStyle?: object;
  span?: string;
}

export const TextArea: React.FC<InputProps> = ({
  name,
  width,
  height,
  placeholder,
  containerStyle,
  span,
  ...rest
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
    setIsFilled(!!inputRef.current?.value);
  }, [fieldName, registerField]);

  return (
    <>
      <Label isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
        {placeholder}
      </Label>
      <Container height={height} width={width}>
        <Content
          width={width}
          style={containerStyle}
          isErrored={!!error}
          isFilled={isFilled}
          isFocused={isFocused}
        >
          {span && <span>{span}</span>}

          <textarea
            placeholder={!isFocused ? placeholder : ""}
            onFocusCapture={() => {
              clearError();
              handleFocus();
            }}
            defaultValue={defaultValue}
            onBlurCapture={handleInputBlur}
            ref={inputRef}
            {...rest}
          />
        </Content>
        <Error>{error}</Error>
      </Container>
    </>
  );
};
