import React, { useContext } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { PrivateApp } from "../Components/Templates";
import { physicalPerson } from "../Constants/RoutesByType";

import { AuthContext } from "../Contexts/Auth";
import GlobalStyle from "../Pages/Public/styles/GlobalStyle";

const Routes = () => {
  const [stateAuthContext] = useContext(AuthContext);
  return (
    <BrowserRouter>
      <Switch>
        {stateAuthContext.token ? (
          <PrivateApp routes={physicalPerson.private} />
        ) : (
          <>
            <GlobalStyle />
            {physicalPerson?.public?.map((route, index) => (
              <Route
                key={index.toString()}
                path={route.path}
                exact
                component={route.component}
              />
            ))}
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
