import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  gap: 10px;
  flex-wrap: wrap;
  display: flex;
`;

export const AddTagInputContainer = styled.div`
  width: 110px;
  height: 30px;
  padding: 5px;
  background-color: white;
  color: ${(props) => props.theme.background.original};
  border: 2px solid ${(props) => props.theme.background.original};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  word-break: break-all;

  input {
    width: 90%;
    outline: 0;
    background: transparent;
    flex: 1;
    border: 0;
    color: #a3a3a3;
    font-size: 0.9em;

    &:disabled {
      color: #a3a3a3;
      cursor: not-allowed;
    }

    &::placeholder {
      color: #a3a3a3;
    }
    & + input {
      margin-top: 8px;
    }
  }

  svg {
    cursor: pointer;
  }
`;
