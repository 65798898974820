import { CardComponent } from "./styles";

interface CardButtonProps {
  icon: string;
}

export const CardButton: React.FC<CardButtonProps> = ({
  children,
  icon,
  ...rest
}) => {
  return (
    <CardComponent>
      <button type="button" {...rest}>
        <p> {icon}</p>
        <p> {children}</p>
      </button>
    </CardComponent>
  );
};

CardComponent.propTypes = {};
