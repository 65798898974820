import styled, { keyframes } from "styled-components";

const bounce = keyframes`
    70% { transform:translateY(0%); }
    80% { transform:translateY(-15%); }
    90% { transform:translateY(0%); }
    95% { transform:translateY(-7%); }
    97% { transform:translateY(0%); }
    99% { transform:translateY(-3%); }
    100% { transform:translateY(0); }
`;

export const Container = styled.div`
  margin: 40px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
`;

export const QrCodeContainer = styled.div`
  display: flex;
  gap: 10;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;

  /* img {
    :hover {
      animation: ${bounce} 2s ease infinite;
    }
    cursor: pointer;
  } */
`;
