import { useSnackbar } from "notistack";
import intl from "react-intl-universal";

const event401 = new Event("error-401");
const logoutErrors = [];

export const useValidateErrorResponse = () => {
  // const { enqueueSnackbar } = useSnackbar();

  const validateError = (response) => {
    const { status, data } = response || {};

    if (status === 401) {
      window.dispatchEvent(event401);
      return;
    }

    const { errors } = data || {};

    if (errors?.length) {
      errors?.map((objError) => {
        if (logoutErrors.indexOf(objError.code) !== -1) {
          window.dispatchEvent(event401);
        }
        // enqueueSnackbar(intl.get(`errors.${objError.code}`), {
        //   variant: "error",
        // });
      });
    }
  };

  return { validateError };
};
