import { useEffect } from "react";
import { useAccountData } from "../../../Hooks";
import DesktopAccount from "./DesktopAccount";

export const Account = ({}) => {
  const {
    state: accountData,
    handleGET: fetchAccountGET,
    loading: accountDataLoading,
  } = useAccountData();

  useEffect(() => {
    fetchAccountGET();
  }, []);

  return (
    <DesktopAccount
      accountData={accountData}
      loading={accountDataLoading.GET}
    />
  );
};
