import { Menu } from "@mui/material";
import { Close, FilterList } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ConvertObjectTranslatedToArray } from "../../../Utils/ConvertObjetctTranslatedToArray";
import {
  CloseButton,
  CollapseContainer,
  Container,
  FilterButton,
  FilterContainer,
  TagFilter,
  TagsContainer,
} from "./styles";
import { PixTablesFilter } from "./FilterComponents/PixTablesFilter";
import { ReceiptFilter } from "./FilterComponents/ReceiptsFilter";
import { ExtractFilter } from "./FilterComponents/ExtractFilter";
import { TransfersFilter } from "./FilterComponents/TransfersFilter";
import { TicketsFilter } from "./FilterComponents/TicketsFilter";
import { useFilter } from "../../../Hooks/useFilter";
import { returningObjectWithoutNullProperties } from "../../../Utils/checkObjectProperties";
import { PixTotalsFilter } from "./FilterComponents/PixTotalsFilter";

interface TableFilterProps {
  tableName:
    | "pixPayment"
    | "pixReceive"
    | "pixTotals"
    | "receipt"
    | "extract"
    | "refunds"
    | "transfers"
    | "tickets";
  setPageToFirst: () => void;
}

export const TableFilter = ({
  tableName,
  setPageToFirst,
}: TableFilterProps) => {
  const [appliedFilters, setAppliedFilters] = useState([] as any);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { getFilterObj, handleRemoveFilters } = useFilter();
  const filterObj = getFilterObj(tableName);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRemoveFiltersByTags = (filterName: string) => {
    const newFilterObject = {
      ...filterObj,
      [filterName]: "",
    };

    handleRemoveFilters(tableName, newFilterObject);
    setPageToFirst();
  };

  useEffect(() => {
    const ObjectWithoutNullValues =
      returningObjectWithoutNullProperties(filterObj);
    const tagsFilter = ConvertObjectTranslatedToArray(ObjectWithoutNullValues);

    setAppliedFilters(tagsFilter);
    handleCloseMenu();
  }, [filterObj]);

  return (
    <Container>
      <FilterContainer>
        {Object.keys(filterObj).length > 0 && (
          <TagsContainer>
            <h3>Filtros Aplicados:</h3>
            {appliedFilters.length > 0 &&
              appliedFilters.map((tag, index) => {
                return (
                  <TagFilter key={index}>
                    <p>{Object.keys(tag)[0]}:</p>
                    <p>{Object.values(tag)[0]}</p>
                    <button
                      onClick={() =>
                        handleRemoveFiltersByTags(Object.keys(tag)[1])
                      }
                    >
                      {!(
                        Object.keys(tag)[1] === "startDate" ||
                        Object.keys(tag)[1] === "start_date"
                      ) && <Close />}
                    </button>
                  </TagFilter>
                );
              })}
          </TagsContainer>
        )}
        <FilterButton onClick={handleOpenMenu}>
          Filtros <FilterList />
        </FilterButton>
      </FilterContainer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <CollapseContainer>
          <CloseButton onClick={() => handleCloseMenu()}>
            fechar <Close />
          </CloseButton>
          {(tableName === "pixPayment" || tableName === "pixReceive") && (
            <PixTablesFilter
              tableName={tableName}
              setPageToFirst={setPageToFirst}
            />
          )}
          {tableName === "pixTotals" && (
            <PixTotalsFilter
              tableName={tableName}
              setPageToFirst={setPageToFirst}
            />
          )}
          {tableName === "receipt" && (
            <ReceiptFilter
              tableName={tableName}
              setPageToFirst={setPageToFirst}
            />
          )}
          {(tableName === "extract" || tableName === "refunds") && (
            <ExtractFilter
              tableName={tableName}
              setPageToFirst={setPageToFirst}
            />
          )}
          {tableName === "transfers" && (
            <TransfersFilter
              tableName={tableName}
              setPageToFirst={setPageToFirst}
            />
          )}
          {tableName === "tickets" && (
            <TicketsFilter
              tableName={tableName}
              setPageToFirst={setPageToFirst}
            />
          )}
        </CollapseContainer>
      </Menu>
    </Container>
  );
};
