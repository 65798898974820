import {
  formatCurrency,
  formatCurrencyColor,
  formatCurrencyType,
  formatDocument,
  formatPhone,
} from "../../Utils/formatters";
import moment from "moment";
import { getTransactionsByDay } from "../../Utils/getTransactionsByDay";

export const normalizeAuthStep2Data = ({ data }) => ({
  created: data?.created,
  tokenApp: data?.token_id,
  expiresIn: data?.expires_in,
});

export const normalizeUserData = ({ data }) => ({
  userLanguage: data?.language || "pt-BR",
  userId: data?.id || 0,
});

export const normalizeAccountData = ({ data }) => ({
  userName: data?.full_name || "",
  userSubTitle:
    `${formatDocument(data?.document, data?.document_type)} - Conta: ${
      data?.account?.acount_code
    }` || "",
  userURL: data?.receipt?.qrcode_link || "",
  userName: data?.full_name || "",
  userId: data?.id || "",
  userEmail: data?.email || "",
  userPhone: formatPhone(data?.phone?.phone_number, "CEL") || "",
  userAddress: `${data?.address?.address}, ${data?.address?.number} - ${data?.address?.district} - ${data?.address?.city} - ${data?.address?.state}`,
});

export const normalizeAccountBalanceData = ({ data }) => ({
  balance: formatCurrency(data?.balance),
  balanceColor: formatCurrencyColor(data?.balance),
  blockedBalance: formatCurrency(data?.blocked_balance),
  blockedBalanceColor: formatCurrencyColor(data?.blocked_balance),
  futureBalance: formatCurrency(data?.scheduled_balance),
  futureBalanceColor: formatCurrencyColor(data?.scheduled_balance),
});

export const normalizeBatchTransfers = ({ data }) =>
  (data || []).map((item) => ({
    document: item.document,
    amount: formatCurrency(item.amount),
    description: item.description,
    favoredName: item.favoredName,
    bank: `${item.bank.code} - ${item.bank.name}`,
    agencyBank: item.agencyBank,
    accountBank: item.accountBank,
    accountType: item.accountType,
    errors: item.errors,
  }));

export const normalizeTransactionsData = ({ data }) => {
  if (data?.items) {
    const result = getTransactionsByDay(data?.items);
    return result || [];
  }

  // return data?.items
  //   ? data.items?.map((item) => ({
  //       title: item?.description,
  //       date: moment(item?.created_at).format("DD/MM/YYYY [às] HH:mm"),
  //       value: formatCurrency(Math.abs(item?.amount)),
  //       valueColor: formatCurrencyColor(item?.amount),
  //       type: formatCurrencyType(item?.amount),
  //       icon: item?.category?.icon,
  //     }))
  //   : !data?.items
  //   ? data?.map((item) => ({
  //       title: item?.description,
  //       date: moment(item?.created_at).format("DD/MM/YYYY [às] HH:mm"),
  //       value: formatCurrency(Math.abs(item?.amount)),
  //       valueColor: formatCurrencyColor(item?.amount),
  //       type: formatCurrencyType(item?.amount),
  //       icon: item?.category?.icon,
  //     }))
  //   : [];
};

export const normalizeReceiptsData = ({ data }) => {
  if (data?.items) {
    const result = getTransactionsByDay(data?.items);
    return result || [];
  }
  // return data?.items
  //   ? data.items?.map((item) => ({
  //       ...item,
  //       value: formatCurrency(item?.amount),
  //       valueColor: formatCurrencyColor(item?.amount),
  //       icon: item?.category?.icon,
  //       title: item?.description,
  //       date: moment(item?.created_at).format("DD/MM/YYYY [às] HH:mm"),
  //     }))
  //   : !data?.items
  //   ? data?.map((item) => ({
  //       ...item,
  //       value: formatCurrency(item?.amount),
  //       valueColor: formatCurrencyColor(item?.amount),
  //       icon: item?.category?.icon,
  //       title: item?.description,
  //       date: moment(item?.created_at).format("DD/MM/YYYY [às] HH:mm"),
  //     }))
  //   : [];
};

export const normalizeTransfersData = ({ data }) =>
  data?.itens
    ? data?.itens?.map((item) => ({
        ...item,
        value: formatCurrency(item?.value),
        valueColor: formatCurrencyColor(item?.value),
      }))
    : [];

export const normalizePaymentMethodsData = ({ data }) => data?.itens || [];

export const normalizeCardData = ({ data }) =>
  data
    ? data?.map((item) => ({
        ...item,
        value: formatCurrency(Math.abs(item?.amount)),
        valueColor: formatCurrencyColor(Math.abs(item?.amount)),
        icon: item?.category?.icon,
        title: item?.description,
        date: moment(item?.created_at).format("DD/MM/YYYY [às] HH:mm"),
        type: item?.operation || "",
      }))
    : [];

export const normalizeCardSettings = ({ data }) => ({
  ...data,
});

export const normalizeContactsData = ({ data }) => data?.itens || [];

export const normalizeCEPData = (values) => ({
  ...values,
});
