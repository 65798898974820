import { useContext, useEffect } from "react";
import { AuthContext } from "../Contexts/Auth";
import useCallAxios from "./useCallAxios";
import { useSessionTimer } from "./useSessionTimer";

export const useRestoreSession = () => {
  const [, dispatchAuthContext] = useContext(AuthContext);
  const context = "restoreSession";
  const { requestData, requestLoading, requestSuccess, fetchPATCH } =
    useCallAxios({ context });

  const { handleSetTimer } = useSessionTimer();

  const handleRestoreSession = () => {
    fetchPATCH({});
  };

  useEffect(() => {
    const { data } = requestData as any;

    if (data?.access_token) {
      dispatchAuthContext({
        type: "setApplicationToken",
        token: data?.access_token,
      });

      handleSetTimer(data?.expires_in);
    }
  }, [requestData]);

  return {
    handleRestoreSession,
    loading: requestLoading.PATCH,
    success: requestSuccess.PATCH,
  };
};
