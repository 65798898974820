import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "90%",
    minHeight: "100%",
    margin: "0px auto",
  },
  formControl: {
    width: 200,
  },

  paper: {
    minWidth: "100%",
    marginBottom: 20,
    marginTop: 20,
  },
  paperHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
    padding: 25,
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listContainer: {
    padding: "0px 10px 10px 10px",
    border: 0,
  },

  cardContainer: {
    marginBottom: 20,
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    width: 400,
    padding: 0,
    borderRadius: 10,
  },
  cardDataContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 20px",
    margin: "-70px 20px 0px 20px;",
    justifyContent: "space-between",

    "& h5": {
      color: "white",
    },
  },

  noCards: {
    width: "100%",
    minHeight: "90%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));
