import styled from "styled-components";
import { AppBar, Drawer, Toolbar } from "@mui/material";

export const Container = styled.div`
  display: flex;
  overflow: hidden;
`;

export const Content = styled.div`
  padding: 20px 40px 20px 40px;
  min-height: 100vh;
  width: 100%;
`;

export const DrawerComponent = styled(Drawer)`
  width: 340px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const AppBarShift = styled(AppBar)`
  width: calc(100% - 340px) !important;
`;

export const AppBarToolbar = styled(Toolbar)`
  width: 100%;
  position: relative;
  height: 100%;
  background-color: #fff;
  color: ${(props) => props.theme.primary.light};
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 40px 20px 40px;
  justify-content: flex-start;
  img {
    cursor: pointer;
  }
`;
