import React from 'react';
import { Button } from '../../Atoms';
import { useStyles } from './styles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Box, Chip, TableFooter } from '@mui/material';
import { PasswordConfirm } from '../PasswordConfirm';
import { useSendBatchTransfers } from '../../../Hooks/useSendBatchTransfers';
import { formatCurrency } from '../../../Utils/formatters';
import { useToast } from '../../../Hooks/toast';
import { useHistory } from 'react-router-dom';
import { ModalLoader } from '../../Atoms/ModalLoader';


function BatchTransfer({ transfers = { items: [], totalAmount: 0, totalTransfers: 0, isInvalid: true }, onClose, file }) {

    const history = useHistory();

    const classes = useStyles()

    const { addToast } = useToast();

    const [showPassword, setShowPassword] = React.useState(false);

    const { sendTransfers, success, loading } = useSendBatchTransfers()


    React.useEffect(() => {
        if(success.POST) {
            addToast({
                type: 'success',
                title: "Sucesso.",
                description: "Transações em lote realizadas com sucesso",
            })
            setShowPassword(false);
            history.push('/')
        }
    }, [success])

    const handleSubmit = password => {      
        if(password.length != 4) return;

        sendTransfers(
            file,
            transfers.items,
            password
        )
    }

    return (
        <div>
            <ModalLoader
                open={loading.POST}
                text="As transferências estão sendo realizadas, aguarde..."
            />
            <div className={classes.header}>
                <Typography variant="h5">
                    Transferencia em Lote
                </Typography>
                <p
                    className={classes.backButton}
                    onClick={() => onClose()}
                >
                    <ArrowBack></ArrowBack>
                    Voltar
                </p>
            </div>

            <Box display={'flex'} marginBottom={'20px'}>
                <Paper sx={{padding: '20px', marginRight: '10px', minWidth: '250px'}}>
                    Total de Transferências
                    <h3 style={{ marginTop: '10px' }}>{transfers.totalTransfers}</h3>
                </Paper>
                <Paper sx={{padding: '20px' , minWidth: '250px'}}>
                    Valor Total
                    <h3 style={{ marginTop: '10px' }} >{formatCurrency(transfers.totalAmount)}</h3>
                </Paper>
            </Box>
            <Box alignItems={'center'} justifyContent={'center'} display={'flex'} flexDirection={'column'}>
                <TableContainer style={{ marginBottom: '30px', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', maxHeight: '600px' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Banco</TableCell>
                                <TableCell>Documento</TableCell>
                                <TableCell>Valor</TableCell>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Nome do beneficiado</TableCell>                             
                                <TableCell>Agência</TableCell>
                                <TableCell>Nº da conta</TableCell>
                                <TableCell>Erros</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                !(transfers.items || []).length && (
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }} colSpan={999}>Não há transações</TableCell>
                                    </TableRow>
                                )
                            }
                            {
                                (transfers.items || []).map((x, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Chip 
                                                label={ x.isInvalid ? 'Inválido' : 'Válido' } 
                                                color={ x.isInvalid ? 'error': 'success' } 
                                            />

                                        </TableCell>
                                        <TableCell>{x.bank.code} - {x.bank.name}</TableCell>
                                        <TableCell>{x.document}</TableCell>
                                        <TableCell>{formatCurrency(x.amount)}</TableCell>
                                        <TableCell>{x.description}</TableCell>
                                        <TableCell>{x.favoredName}</TableCell>
                                        <TableCell>{x.agencyBank}</TableCell>
                                        <TableCell>{x.accountNumber}</TableCell>                                        
                                        <TableCell>
                                            { x.errors.join('|') }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button disabled={transfers.isInvalid} title={transfers.isInvalid ? "Corrija os erros do arquivo e tente novamente." : 'Concluir transações'} onClick={() => setShowPassword(true)}>
                    Finalizar transações
                </Button>

                <PasswordConfirm
                    submitPassword={handleSubmit}
                    open={showPassword}
                    onClose={() => setShowPassword(false)}
                />
            </Box>
        </div>
    )
}



export {
    BatchTransfer
}