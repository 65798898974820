import styled, { keyframes, css } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  @media (max-width: 768px) {
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    p,
    button,
    img,
    span {
      width: 88%;
    }

    p {
      color: ${(props) => props.theme.fonts.title};
      font-weight: 700;
      font-size: 1.8em;
    }

    span {
      color: ${(props) => props.theme.fonts.subtitle};
      font-weight: 400;
      font-size: 1.2em;
    }
  }
`;
