import { normalizeAccountData } from "./Normalizers";
import useCallAxios from "./useCallAxios";
import { UserContext } from "../Contexts/User";
import React from "react";

const filterQuery = (values) => ({ ...values });

export const useAccountData = () => {
  const [, dispatchUserContext] = React.useContext(UserContext);
  const context = `accountSettings`;
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({
      context,
    });

  const state = normalizeAccountData(requestData);
  const { userName, userId } = state;

  React.useEffect(() => {
    if (userName?.length && userId?.length) {
      dispatchUserContext({
        type: "setSettingsUser",
        userId,
        userName,
      });
    }
  }, [userName, userId]);

  const handleGET = (params) => {
    fetchGET({ context, queryParameters: filterQuery(params) });
  };

  sessionStorage.setItem("@BCBankline:Qrcode", state.userURL);

  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handleGET,
  };
};
