import { Loading } from "../Loading";
import { Container } from "./styles";

export const WhiteLabelLoading = () => {
  return (
    <Container>
      <Loading />
      <span>Carregando dados . . .</span>
    </Container>
  );
};
