import {
  Content,
  ReceiptTitle,
  ReceiptValue,
  ReceiptFooter,
  ReceiptDetailsContent,
  HelpTooltip,
} from "./styles";

import { cnpjMask, cpfMask } from "../../../Utils/masks";
import { formatCurrency } from "../../../Utils/formatters";
import { FiHelpCircle } from "react-icons/fi";
import { useEffect } from "react";
import { useState } from "react";

const BarcodeDataDetails = ({ receipt }) => {
  const [tooltipValue, setTooltipValue] = useState();

  useEffect(() => {
    !!receipt.barcode_data?.charges && receipt.barcode_data?.charges > 0
      ? setTooltipValue(`
      Valor de taxas: ${formatCurrency(receipt.barcode_data?.charges)}
    `)
      : setTooltipValue(`
        Este pagamento não possui taxas
      `);
  }, [receipt]);

  return receipt ? (
    <Content>
      <ReceiptTitle>
        <div>
          <h3>Pagamento de boleto</h3>

          <ReceiptValue>
            <h2>{formatCurrency(receipt?.barcode_data.amount)}</h2>
            <HelpTooltip title={tooltipValue}>
              <FiHelpCircle></FiHelpCircle>
            </HelpTooltip>
          </ReceiptValue>
          <h4>Data do vencimento do boleto {receipt?.barcode_data.due_date}</h4>
        </div>
        <hr></hr>
      </ReceiptTitle>
      <ReceiptDetailsContent>
        <div>
          <p>Pagamento para:</p>
        </div>
        <div>
          <h4>{receipt?.barcode_data.recipient_data.legal_name}</h4>
          <span>
            {receipt?.barcode_data.recipient_data.tax_id.length > 11
              ? cnpjMask(receipt?.barcode_data.recipient_data.tax_id)
              : cpfMask(receipt?.barcode_data.recipient_data.tax_id)}
          </span>
        </div>
        <div>
          <p>Instituição Bancária:</p>
        </div>
        <div>
          <h4>{receipt?.barcode_data.bank.name}</h4>
          <span>código do banco: {receipt?.barcode_data.bank.code}</span>
        </div>
        <div>
          <h4>Data do pagamento</h4>
          <span>{receipt?.barcode_data.payment}</span>
        </div>
      </ReceiptDetailsContent>
      <ReceiptFooter>
        <hr></hr>
        {!!receipt?.barcode_data.amount_receivable && (
          <p>
            Valor à pagar:{" "}
            {formatCurrency(receipt?.barcode_data.amount_receivable)}
          </p>
        )}
        {!!receipt?.barcode_data.document_amount && (
          <p>
            Valor do documento:{" "}
            {formatCurrency(receipt?.barcode_data.document_amount)}
          </p>
        )}
        {!!receipt?.barcode_data.discount && (
          <p>
            Valor de desconto: {formatCurrency(receipt?.barcode_data.discount)}
          </p>
        )}
        {!!receipt?.barcode_data.charges && (
          <p>Valor de taxas: {formatCurrency(receipt?.barcode_data.charges)}</p>
        )}
      </ReceiptFooter>
    </Content>
  ) : (
    <div>Boleto vencido</div>
  );
};

export default BarcodeDataDetails;
