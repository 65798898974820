import { isAfter, isBefore, sub } from "date-fns";

export const validateDate = (
  startDate: string | null,
  endDate: string | null
) => {
  if (startDate === "" && endDate === "") {
    return "";
  }

  //Verifica se as datas estão em um formato válido
  const regex = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/;
  if (
    !regex.test(startDate) ||
    isBefore(new Date(startDate), sub(new Date(), { years: 1 })) ||
    isAfter(
      new Date(startDate.replace(/-/g, "/").replace(/T.+/, "")),
      new Date()
    ) ||
    !regex.test(endDate) ||
    isBefore(
      new Date(endDate.replace(/-/g, "/").replace(/T.+/, "")),
      sub(new Date(), { years: 1 })
    ) ||
    isAfter(new Date(endDate.replace(/-/g, "/").replace(/T.+/, "")), new Date())
  ) {
    return "invalid date";
  }

  //Verifica se a data inicial não é maior que a data final
  if (
    isAfter(
      new Date(startDate.replace(/-/g, "/").replace(/T.+/, "")),
      new Date(endDate.replace(/-/g, "/").replace(/T.+/, ""))
    )
  ) {
    return "invalid initialDate";
  }
};
