import { Dialog, DialogContent, Grid } from "@mui/material";
import styled from "styled-components";
export const DialogContainer = styled(Dialog)`
  width: 100%;
`;

export const DialogBox = styled(DialogContent)`
  width: 100%;
  padding: 0 !important;
  padding-top: 0px !important;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: white;
  text-align: center;
  background-color: #2f3e9e;
  padding-top: 0;
  padding: 20px;
  font-size: 22px;

  svg {
    color: white;
  }
`;

export const DigitButton = styled.input`
  width: 75%;
  height: 70px;
  font-size: 22px;
  color: #444;
  font-weight: 400;
  border: 0;
  box-shadow: 1px 1px rgba(48, 63, 159, 0.2);
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    border: 2px solid rgba(48, 63, 159, 0.3);
  }
`;

export const ClearButton = styled.button`
  width: 75%;
  height: 70px;
  border: 0;
  font-size: 22px;
  color: #444;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: 1px 1px rgba(229, 0, 0, 0.2);
  &:hover {
    cursor: pointer;
    border: 2px solid rgba(229, 0, 0, 0.3);
  }
`;

export const PasswordContainer = styled(Grid)`
  display: flex;
  padding: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
`;

export const PasswordHidden = styled.input`
  cursor: not-allowed;
  width: 100%;
  color: #777;
  border: 1.5px solid #dbdfea;
  border-radius: 4px;

  padding: 0.35rem 1rem;
  text-align: center;
  font-size: 32px;

  ::placeholder {
    font-size: 18px;
  }
`;

export const DigitGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`;
