import styled, { css } from "styled-components";

interface FadeTextProps {
  toShow: boolean;
}

interface SVGContainerProps {
  toPulse: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: #b79568;
  font-size: 0.8em;

  svg {
  }
`;

export const SVGContainer = styled.div<SVGContainerProps>`
  display: flex;
  position: relative;
  cursor: pointer;
  svg {
    font-size: 1.2rem;
  }
  &::before {
    content: "";
    position: absolute;
    z-index: 0;

    top: -2px;
    left: 0;
    width: 20px;
    height: 20px;
    ${(props) =>
      props.toPulse
        ? css`
            box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
            border-radius: 50%;
            animation: pulse-blue 2s infinite;
          `
        : ""}
  }

  @keyframes pulse-blue {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 5px rgba(52, 172, 224, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
  }
`;

export const FadeText = styled.p<FadeTextProps>`
  overflow: hidden;
  white-space: nowrap;

  ${(props) =>
    props.toShow
      ? css`
          animation: showText 2s steps(100, end);
        `
      : css`
          animation: hideText 4s steps(100, end) forwards;
        `}

  @keyframes showText {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
  @keyframes hideText {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }
`;
