import styled, { keyframes, css } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  @media (max-width: 768px) {
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  padding-top: 25px;
  padding-bottom: 25px;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  height: 100%;
`;

export const VerificationContainer = styled.div`
  display: flex;
  height: 90%;

  flex-direction: column;
  padding: 4rem;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  gap: 20px;

  button {
    width: 14rem;
    svg {
      vertical-align: sub;
    }
  }

  img + p {
    font-weight: 700;
    font-size: 1.3rem;
  }

  p {
    width: 80%;
    text-align: center;
    line-height: 1.4rem;
  }

  a {
    color: ${(props) => props.theme.primary.light};
  }

  .custom-styles {
    cursor: pointer;
    --ReactInputVerificationCode-itemWidth: 2.5rem;
    --ReactInputVerificationCode-itemHeight: 2.5rem;
  }

  .custom-styles .ReactInputVerificationCode__item {
    position: relative;
    color: #262626;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    width: 100%;
    border: 0;
  }
`;
