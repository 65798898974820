import {
  AirlineSeatFlat as AirlineSeatFlatIcon,
  Fastfood as FastfoodIcon,
  Healing as HealingIcon,
  Payment as PaymentIcon,
} from "@mui/icons-material";

export const staticButtons = [
  "PIX",
  "Enviar Dinheiro",
  "Pagar Conta",
  "Depositar",
  "Cobrar",
  "QR Code ELO",
  "Pagar com QR Code",
  "Cartão Virtual",
];

export const staticTransactions = [
  {
    value: 145.98,
    title: "OBA HORTIFRUTI",
    date: "21 de Junho de 2021",
    icon: FastfoodIcon,
  },
  {
    value: -32.5,
    title: "DROGA RAIA",
    date: "21 de Junho de 2021",
    icon: HealingIcon,
  },
  {
    value: 15.0,
    title: "PAGAMENTO",
    date: "21 de Junho de 2021",
    icon: PaymentIcon,
  },
  {
    value: 0,
    title: "SALÃO BEM ESTAR",
    date: "21 de Junho de 2021",
    icon: AirlineSeatFlatIcon,
  },
];

export const staticReceipts = [
  {
    value: -32.5,
    title: "Pagamento de Boleto",
    date: "21 de Junho de 2021",
    icon: HealingIcon,
    type: "ticket",
  },
  {
    value: 15.0,
    title: "Antecipação",
    date: "21 de Junho de 2021",
    icon: PaymentIcon,
    type: "card",
  },
];

export const staticTransfers = [
  {
    value: -32.5,
    name: "OBA HORTIFRUTI",
    date: "21 de Junho de 2021",
    icon: HealingIcon,
  },
  {
    value: 15.0,
    name: "DROGA RAIA",
    date: "21 de Junho de 2021",
    icon: PaymentIcon,
  },
];

export const staticContacts = [
  {
    name: "Abu Bin",
    email: "abu-bin@hotmail.com",
    bankId: 1,
    agency: "0001-1",
    account: "12345-6",
  },
  {
    name: "Ricardo Abdo",
    email: "ricardo-abdo@hotmail.com",
    bankId: 1,
    agency: "0001-1",
    account: "12345-6",
  },
];

export const accountData = {
  companyName: "{title} Soluções em Pagamentos LTDA",
  companySubTitle: "30.507.541/0001-71 - CONTA: 3849203",
  companyURL: "https://google.com.br",
};

export const userData = {
  userName: "Ricardo Abdo",
};

export const balanceData = {
  balance: 1000.0,
  blockedBalance: -50.0,
  futureBalance: 100.0,
};

export const cardData = {
  number: "**** **** **** 3010",
  name: "MOHAN KHADKA",
  expirationDate: "01/2018",
  flag: "ELO",
};

export const staticTickets = [
  {
    value: 32.5,
    recipient: "Leandro Abade",
    due_date: "21 de Junho de 2021",
    issue_date: "20 de Junho de 2021",
    number: "4893302-2",
    status: "expired",
  },
  {
    value: 100,
    recipient: "Leandro Abade",
    due_date: "21 de Junho de 2021",
    issue_date: "20 de Junho de 2021",
    number: "4893302-2",
    status: "active",
  },
];
