import { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { KeySelectionContainer } from "../../../Pages/Private/Pix/PixTransfers/styles";
import { convertToCents, stringToDecimal } from "../../../Utils/formatters";
import { ArrowBack } from "@mui/icons-material";
import { Button } from "../../Atoms";
import { InputMoney } from "../../Atoms/InputMoney";
import { Header } from "./styles";
import getValidationErrors from "../../../Utils/getValidationErrors";
import { ScreenGreeting } from "../ScreenGreeting";

interface FirstStepProps {
  handleSetTransferAmount: (amount: number) => void;
  handleSetStep: (step: number) => void;
}

export const FirstStepPixTransfer = ({
  handleSetTransferAmount,
  handleSetStep,
}: FirstStepProps) => {
  const history = useHistory();
  const formRef = useRef(null);

  const handleSubmitValue = useCallback(async (data) => {
    const amount = convertToCents(data.value);
    data.value = stringToDecimal(data.value);

    const schema = Yup.object().shape({
      value: Yup.number()
        .typeError("Digite o valor do link")
        .required("Digite o valor do link")
        .moreThan(0, "Valor deve ser maior que R$ 0.00"),
    });
    try {
      await schema.validate(data, {
        abortEarly: false,
      });
      handleSetTransferAmount(amount);
      handleSetStep(1);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  }, []);
  return (
    <>
      <ScreenGreeting
        title={"Transferência Pix"}
        subTitle={"Insira o valor a ser transferido"}
      />
      <KeySelectionContainer>
        <Header>
          <h6>Quanto você deseja transferir?</h6>
          <p
            onClick={() => {
              history.push("/pix");
            }}
          >
            <ArrowBack />
            Voltar
          </p>
        </Header>

        <Form onSubmit={handleSubmitValue} ref={formRef} style={{ width: 630 }}>
          <InputMoney />
          <Button type="submit">Continuar</Button>
        </Form>
      </KeySelectionContainer>
    </>
  );
};
