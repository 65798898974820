// import { normalizeTransactionsData } from "./Normalizers";
import useCallAxios from "./useCallAxios";

export const useBarcodePayment = () => {
  const context = `barcodePayment`;
  const { requestSuccess, requestData, requestLoading, fetchPOST } =
    useCallAxios({
      context,
    });

  const handlePOST = (params) => {
    fetchPOST({ body: params });
  };

  return {
    loading: requestLoading,
    state: requestData.data,
    success: requestSuccess,
    handlePOST,
  };
};
