import React, { useEffect, useState } from "react";
import { Container, Timer, SMSResend } from "./styles";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

interface ICountdownData {
  expireTime: number;
  isExpired: any;
  resendCode: (type: string) => void;
}

export const Countdown: React.FC<ICountdownData> = ({
  expireTime,
  resendCode,
  isExpired,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [minuteTimer, setMinuteTimer] = useState<any>(
    Math.floor(expireTime / 60)
  );
  const [isActive, setIsActive] = useState(true);
  const [resetTimer, setResetTimer] = useState(false);
  const [totalTimer, setTotalTimer] = useState(expireTime);
  const [secondTimer, setSecondTimer] = useState<any>(expireTime % 60);

  useEffect(() => {
    isExpired(!isActive);
  }, [isActive]);

  const verificationCodeTimeout = useCallback(() => {
    setIsActive(false);
    enqueueSnackbar("O token expirou clique em reenviar SMS para renová-lo", {
      variant: "warning",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  }, []);

  useEffect(() => {
    if (resetTimer) {
      setMinuteTimer(Math.floor(expireTime / 60));
      setTotalTimer(expireTime);
      setSecondTimer(expireTime % 60);
    }
  }, [resetTimer]);

  useEffect(() => {
    setIsActive(true);
    setResetTimer(false);

    if (!totalTimer && !minuteTimer && !secondTimer) {
      verificationCodeTimeout();
      return;
    }
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTotalTimer(totalTimer - 1);

      if (secondTimer > 0) {
        setSecondTimer(secondTimer - 1);
      }
      if (secondTimer === 0 && minuteTimer > 0) {
        setMinuteTimer(minuteTimer - 1);
        setSecondTimer(59);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [minuteTimer, secondTimer]);

  return (
    <Container>
      <Timer isActive={isActive}>
        {minuteTimer >= 10
          ? minuteTimer.toString()
          : 0 + minuteTimer.toString()}
        :
        {secondTimer >= 10
          ? secondTimer.toString()
          : 0 + secondTimer.toString()}
      </Timer>
      <SMSResend
        isActive={isActive}
        disabled={isActive}
        onClick={() => {
          setResetTimer(true);
          resendCode("sms");
        }}
      >
        Reenviar SMS
      </SMSResend>
      <span>OU</span>
      <SMSResend
        isActive={isActive}
        disabled={isActive}
        onClick={() => {
          setResetTimer(true);
          resendCode("email");
        }}
      >
        Reenviar via E-mail
      </SMSResend>
    </Container>
  );
};
