export const Endpoints = {
  GET: {
    //ACCOUNT

    theme: "/",

    accountSettings: `/accounts`,
    accountBalance: `/accounts/balance`,
    accountStatement: `/accounts/statements`,
    accountPixTotals: `/accounts/totals`,

    excelStatement: `/accounts/statements/excel`,
    csvStatement: `/accounts/statements/csv`,
    //PAYMENTS
    consultBarcode: `/payments/barcode/`,

    //ACCOUNT BC LIST
    valutAccounts: `/transfers/accounts`,

    //BANK LIST
    banksList: `/banks`,

    //CARDS
    cardSettings: `/cards`,
    cardStatement: `/cards/statements`,

    //CONTACTS
    contactStatement: ``,

    //RECEIPTS
    receiptStatement: `/receipts`,

    //TRANSFERS
    transferStatement: `/transfers`,

    //TICKETS
    ticketStatement: `/boleto`,

    //PIX
    pixList: `/pix/transactions`,
    pixCards: `/pix/cards`,
    pixPayments: `/pix/payments`,
    pixReceive: `/pix/receive`,

    products: `/utils/products`,
  },
  PUT: {
    new_password: ``,
    reset_password: ``,
    logout: ``,
    confirmPix: `/pix`,
  },
  POST: {
    //AUTH
    login: `/auth`,
    smsToken: `/auth/token`,

    //TRANSFERS
    transactionPay: `/transfers`,

    //RECEIPTS
    ticketStatement: `/boleto`,

    //PAYMENTS
    barcodePayment: `/payments/barcode`,

    //PIX
    createPixQRCode: `/pix/charge`,
    pixPayment: `/pix`,

    //BATCH TRANSFERS
    receiveValidateBatchTransfers: "/transfers/batch/send-file",
    sendBatchTransfers: "/transfers/batch",
  },
  PATCH: { restoreSession: `/auth/refresh_token` },
  DELETE: {
    //TICKETS
    ticketStatement: `/boleto`,

    returnPix: `/pix`,
  },
};
