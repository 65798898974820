import axios from "axios";

let apiURL;

const token = sessionStorage.getItem("token");
const options = sessionStorage.getItem("Options");

let headers;

headers = {
  "branch-id": options?.branch_id,
  Authorization: `Bearer ${JSON.parse(token)}`,
};

process.env.NODE_ENV === "production"
  ? (apiURL = process.env.REACT_APP_API_URL)
  : (apiURL = process.env.REACT_APP_API_URL);

const api = axios.create({
  baseURL: apiURL,
  headers,
});

export default api;
