import moment from "moment";

import {
  Card,
  CardsWrapper,
  ChartWrapper,
  Container,
  Content,
  DateInfo,
  LabelsContainer,
} from "./styles";

import { useEffect, useState } from "react";
import useCallAxios from "../../../Hooks/useCallAxios";
import { formatCurrency } from "../../../Utils/formatters";
import { Loading } from "../../Atoms";
import { format } from "date-fns";

export const PixCards = () => {
  const [dataCard, setDataCard] = useState<any>();

  const context = "pixCards";

  const {
    fetchGET: fetchPixCards,
    requestLoading: loading,
    requestData: dataPixCards,
  } = useCallAxios({ context, responseType: "" });

  useEffect(() => {
    fetchPixCards({});
  }, []);

  useEffect(() => {
    const dataPix = dataPixCards as any;
    setDataCard(dataPix?.data);
  }, [dataPixCards]);

  console.log(dataCard?.data);

  return (
    <Container>
      <DateInfo>
        dados dos cartões referentes à {format(new Date(), "MMM d, yyyy")}
      </DateInfo>
      <CardsWrapper>
        <Card>
          <p>Valor Recebido</p>
          <Content>
            {loading.GET ? (
              <Loading />
            ) : (
              <p>{formatCurrency(dataCard?.total_received_amount)}</p>
            )}
          </Content>
        </Card>
        <Card>
          <p>Valor Pago</p>
          <Content>
            {loading.GET ? (
              <Loading />
            ) : (
              <p>{formatCurrency(dataCard?.total_paid_amount)}</p>
            )}
          </Content>
        </Card>
        <Card>
          <p>Valor Previsto para Recebimento</p>
          <Content>
            {loading.GET ? (
              <Loading />
            ) : (
              <p>{formatCurrency(dataCard?.pending_receive_amount)}</p>
            )}
          </Content>
        </Card>
      </CardsWrapper>
    </Container>
  );
};
