import React, { ButtonHTMLAttributes } from "react";

import { Container, Loader } from "./styles";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  disabled?: boolean;
  action?: string;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  action,
  ...rest
}) => {
  return (
    <Container action={action}>
      <button type="button" disabled={loading} {...rest}>
        {loading ? (
          <span arial-label="loading">
            <Loader />
          </span>
        ) : (
          children
        )}
      </button>
    </Container>
  );
};
