import { normalizeTransactionsData } from "./Normalizers";
import useCallAxios from "./useCallAxios";

const filterQuery = (values) => ({ ...values });

export const useTransactionsData = () => {
  const context = `accountStatement`;
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({
      context,
    });

  const handleGET = (params) => {
    fetchGET({ context, queryParameters: filterQuery(params) });
  };

  const state = normalizeTransactionsData(requestData);

  const data = {
    ...requestData.data,
    items: state,
  };

  return {
    reset,
    loading: requestLoading,
    state: data,
    success: requestSuccess,
    handleGET,
  };
};
