// import { normalizeTransactionsData } from "./Normalizers";
import useCallAxios from "./useCallAxios";

const filterQuery = (values) => ({ ...values });

export const useBanksList = () => {
  const context = `banksList`;
  const { requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({
      context,
    });

  const handleGET = (params) => {
    fetchGET({ context, queryParameters: filterQuery(params) });
  };

  return {
    loading: requestLoading,
    state: requestData.data,
    success: requestSuccess,
    handleGET,
  };
};
