import { Typography } from "@mui/material";
import { Height } from "@mui/icons-material";
import { Button } from "../../Atoms/Button";

export const ScreenGreeting = ({
  title = "",
  subTitle = "",
  hasButton = false,
  imgProperties = "",
  imgUrl = "",
  buttonTitle = "",
  handleClick = () => {},
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        margin: "40px 0px",
      }}
    >
      <div>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        {subTitle && (
          <Typography variant="subtitle1" gutterBottom>
            {subTitle}
          </Typography>
        )}
      </div>
      {hasButton && (
        <div>
          <Button onClick={handleClick}>{buttonTitle}</Button>
        </div>
      )}
      {!!imgProperties && (
        <div>
          <img
            src={imgUrl}
            alt="qrCode"
            style={{ width: imgProperties.width, height: imgProperties.height }}
          />
        </div>
      )}
    </div>
  );
};

ScreenGreeting.propTypes = {};
