import { ArrowBack, CheckCircleSharp, ErrorRounded } from "@mui/icons-material";
import { useEffect } from "react";
import { useBalance } from "../../../../Hooks/useBalance";
import { Container, Content } from "./styles";
import ReceiptDetails from "../../../../Components/Molecules/ReceiptModal/receiptDetails";

export const FinalStep = ({
  transactionStatus,
  transactionData,
  refreshSteps,
}) => {
  const { getBalance: refreshBalance } = useBalance();

  useEffect(() => {
    transactionStatus === true && refreshBalance();
  }, []);

  return transactionStatus ? (
    <Container>
      <p onClick={() => refreshSteps()}>
        <ArrowBack></ArrowBack>
        Voltar para transferências pix
      </p>
      <h4>
        Transferência realizada com sucesso <CheckCircleSharp />
      </h4>

      <Content>
        <ReceiptDetails
          receipt={transactionData}
          noModal={true}
        ></ReceiptDetails>
      </Content>
    </Container>
  ) : (
    <Container>
      <p onClick={() => refreshSteps()}>
        <ArrowBack></ArrowBack>
        Voltar para transferências pix
      </p>

      <Content>
        <h4>
          Transferência Negada <ErrorRounded></ErrorRounded>{" "}
        </h4>
      </Content>
    </Container>
  );
};
