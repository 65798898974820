import { Grid, List, ListItemButton, Typography } from "@mui/material";
import styled, { css } from "styled-components";

interface ISelectedProps {
  isSelected: boolean;
}

export const Container = styled(Grid)`
  padding: 20px 40px 20px 40px;
  display: flex;
`;

export const Subtitle = styled(Typography)`
  color: ${(props) => props.theme.primary.original};
  font-weight: 700;
`;

export const Menu = styled(List)`
  margin: 0px;
  padding: 0px;
`;

export const MenuItem = styled(ListItemButton)<ISelectedProps>`
  margin-bottom: 5px;
  color: #666a70;
  padding-left: 0px;
  font-weight: 700;
  border-radius: 4px !important;

  ${(props) =>
    props.isSelected
      ? css`
          background: ${(props) => props.theme.primary.light} !important;
          color: white !important;

          svg {
            color: white !important;
          }
        `
      : css`
          :hover {
            color: ${(props) => props.theme.primary.light};
            background: #f9f8f8 !important;

            svg {
              color: ${(props) => props.theme.primary.light};
            }
          }
        `}

  svg {
    color: #666a70;
    font-size: 24px;
  }

  a {
    text-decoration: none !important;
    color: inherit;
  }
`;
