import styled from "styled-components";

export const Button = styled.p`
  width: fit-content;
  color: #777;
  cursor: pointer;

  svg {
    margin-right: 5;
    vertical-align: middle;
    font-size: 20;
  }

  :hover {
    color: ${(props) => props.theme.primary.light};
    transition: 1s;
  }
`;
