import { ArrowBack, CheckCircleSharp, ErrorRounded } from "@mui/icons-material";
import { useEffect } from "react";
import { useBalance } from "../../../Hooks/useBalance";
import ReceiptDetails from "../ReceiptModal/receiptDetails";
import { useStyles } from "./styles";

export const FinalStep = ({
  transactionStatus,
  transactionData,
  refreshSteps,
}) => {
  const classes = useStyles();
  const { getBalance: refreshBalance } = useBalance();

  useEffect(() => {
    transactionStatus === true && refreshBalance();
  }, []);

  return (
    <div>
      <p className={classes.backButton} onClick={() => refreshSteps()}>
        <ArrowBack></ArrowBack>
        Voltar para transferências
      </p>
      {transactionStatus ? (
        <>
          <h4 style={{ textAlign: "center", paddingTop: 40, fontSize: 26 }}>
            Transferência realizada com sucesso <CheckCircleSharp />
          </h4>

          <div
            style={{
              minHeight: "40%",
              paddingTop: 40,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <ReceiptDetails
              receipt={transactionData}
              noModal={true}
            ></ReceiptDetails>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "60vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ fontSize: 30 }}>
            Transferência Negada <ErrorRounded></ErrorRounded>{" "}
          </h4>
        </div>
      )}
    </div>
  );
};
