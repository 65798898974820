import { useEffect, useState } from "react";
import { loadItem, saveItem } from "../Utils";
import useCallAxios from "./useCallAxios";

export const useGetSiteOptions = () => {
  const filterQuery = (values) => ({ ...values });
  const context = "theme";

  const [options, setOptions] = useState();
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({
      context,
    });

  const { data } = requestData;

  const handleGET = (params) => {
    if (loadItem("Options")) {
      setOptions(loadItem("Options"));
    } else {
      fetchGET({ context, queryParameters: filterQuery(params) });
    }
  };

  useEffect(() => {
    saveItem(data, "Options");
    setOptions(data);
  }, [data]);

  return {
    reset,
    loading: requestLoading,
    data: options,
    success: requestSuccess,
    handleGET,
  };
};
