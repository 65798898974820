import { ScreenGreeting } from "../../../../Components/Molecules";
import { Container, Header, KeySelectionContainer } from "./styles";
import { useCallback, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { Button, TextArea } from "../../../../Components/Atoms";
import getValidationErrors from "../../../../Utils/getValidationErrors";
import { useHistory } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import { PixPreview } from "../../../../Components/Molecules";
import { InitiatePixPayment } from "../../../../Hooks/UsePix";
import { FinalStep } from "../FinalStep";
import api from "../../../../Services/api";
import { useToast } from "../../../../Hooks/toast";
import { ModalLoader } from "../../../../Components/Atoms/ModalLoader";

export const PixPayment = () => {
  const formRef = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [confirmPaymentState, setConfirmPaymentState] = useState();
  const balance = Number(sessionStorage.getItem("@BCBankline:Balance"));
  const [loadingPixConfirmation, setLoadingPixConfirmation] = useState(false);
  const {
    handlePOST: fetchInitiatePayment,
    state: initiatePaymentState,
    loading: initiatePaymentLoading,
    success: initiatePaymentSuccess,
  } = InitiatePixPayment();

  const resetStates = useCallback(() => {
    setStep(0);
    setConfirmPaymentState();
  }, []);

  const handleFinishTransaction = useCallback(async (obj) => {
    const { validation, amount, pix_id } = obj;

    let payload = {
      validation,
      amount,
    };

    setLoadingPixConfirmation(true);

    await api
      .put(`/pix/${pix_id}`, payload)
      .then((res) => {
        setConfirmPaymentState(res.data);
        setStep(2);
      })
      .catch((err) => {
        history.push("/");
        addToast({
          description: err.response.data.description,
          title: err.response.data.information,
          type: "error",
        });
      })
      .finally(() => {
        setLoadingPixConfirmation(false);
      });
  }, []);

  const handleSubmit = useCallback(async (data) => {
    try {
      const schema = Yup.object().shape({
        qrCode: Yup.string().required("Código obrigatório"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      let payload = {
        mode: "qrcode",
        qr_code: data.qrCode,
      };

      fetchInitiatePayment(payload);
    } catch (err) {
      history.push("/");
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  }, []);

  useEffect(() => {
    !!initiatePaymentState && initiatePaymentSuccess.POST && setStep(1);
  }, [initiatePaymentState, initiatePaymentSuccess.POST]);

  //
  const step0 = (
    <>
      <ScreenGreeting
        title={"Pix copia e cola"}
        subTitle={"Pague um Pix com o código do QR Code"}
      />
      <KeySelectionContainer>
        <Header>
          <h6>Informe o código recebido abaixo para realizar o pagamento</h6>
          <p
            onClick={() => {
              history.push("/pix");
            }}
          >
            <ArrowBack />
            Voltar
          </p>
        </Header>
        <Form
          type="submit"
          onSubmit={handleSubmit}
          ref={formRef}
          style={{ width: '100%' }}
        >
          <TextArea placeholder="Código QRCode" name="qrCode" height={"8rem"} />
          <Button type="submit" loading={initiatePaymentLoading.POST}>
            Continuar
          </Button>
        </Form>
      </KeySelectionContainer>
    </>
  );

  return (
    <Container>
      <ModalLoader
        open={loadingPixConfirmation}
        text={"Finalizando transferência"}
      />
      {step === 0 && step0}
      {step === 1 && (
        <PixPreview
          isCustomValue={!!(initiatePaymentState.amount === 0)}
          finishTransaction={(e) => handleFinishTransaction(e)}
          cancelOperation={() => setStep(0)}
          previewData={initiatePaymentState}
          isLoading={loadingPixConfirmation}
        />
      )}
      {step === 2 && !loadingPixConfirmation && (
        <FinalStep
          transactionStatus={!!confirmPaymentState}
          transactionData={confirmPaymentState}
          refreshSteps={() => {
            resetStates();
          }}
        />
      )}
    </Container>
  );
};
