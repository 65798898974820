import axios from "axios";
import qs from "qs";
import { loadItem } from "../Utils";
import { Endpoints } from "./Endpoints";

const createHeaders = () => {
  const token = loadItem("token");
  const options = loadItem("Options");

  const headers = {
    "branch-id": options?.branch_id,
    Authorization: "",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

export const fetchAxios = ({
  verb,
  context,
  responseType,
  queryParameters = null,
  id = null,
  afterID = null,
  body = null,
  cancelToken,
  additionalHeaders = {}
}) => {
  return new Promise((resolve, reject) => {
    const headers = { ...additionalHeaders, ...createHeaders()};

    let result = null;
    let valueId = "";
    let valueAfterId = "";
    let query = "";
    let bodyValues = {};

    if (
      id &&
      (typeof id === "string" ||
        typeof id === "number" ||
        typeof id === "boolean")
    ) {
      valueId = `/${id.toString()}`;
    }
    if (
      afterID &&
      (typeof afterID === "string" ||
        typeof afterID === "number" ||
        typeof afterID === "boolean")
    ) {
      valueAfterId = `/${afterID.toString()}`;
    }

    if (typeof queryParameters === "object") {
      query = qs.stringify(queryParameters, {
        addQueryPrefix: true,
      });
    }

    const url = `${
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL
    }${Endpoints[verb][context]}${valueId}${valueAfterId}${query}`;

    switch (verb) {
      case "GET":
        result = axios.get(url, {
          headers,
          responseType,
          cancelToken,
        });
        break;
      case "PUT":
        result = axios.put(url, body, {
          headers,
          cancelToken,
        });
        break;
      case "POST":
        result = axios.post(url, body, {
          headers,
          cancelToken,
        });
        break;
      case "DELETE":
        result = axios.delete(url, {
          headers,
          cancelToken,
        });
        break;
      case "PATCH":
        result = axios.patch(url, body, {
          headers,
          cancelToken,
        });
        break;
      default:
        break;
    }

    result.then((data) => resolve(data)).catch((err) => reject(err));
  });
};
