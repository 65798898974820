import { Dialog, Slide } from "@mui/material";
import React from "react";
import ReceiptDetails from "./receiptDetails";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReceiptModal = ({ open, onClose, selectedItem, type }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <ReceiptDetails receipt={selectedItem} onClose={onClose} type={type} />
    </Dialog>
  );
};

export default ReceiptModal;
