import React, { useEffect } from "react";
import { FiAlertCircle, FiInfo, FiXCircle, FiHelpCircle } from "react-icons/fi";
import { ToastMessage, useToast } from "../../../../Hooks/toast";
import { Container } from "./styles";

interface ToastProps {
  message: ToastMessage;
  style: object;
}

const icons = {
  info: <FiInfo size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiAlertCircle size={24} />,
  warning: <FiHelpCircle size={24} />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();
  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message]);
  return (
    <Container
      type={message.type}
      hasdescription={Number(!!message.description)}
      style={style}
    >
      {icons[message.type || "info"]}
      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button onClick={() => removeToast(message.id)} type="button">
        <FiXCircle style={{ cursor: "pointer" }} size={20} />
      </button>
    </Container>
  );
};

export default Toast;
