import { FormEvent, useState } from "react";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format, isAfter, isBefore, sub } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { Stack } from "@mui/material";
import {
  ButtonsWrapper,
  DatePickerContainer,
  DatePickerField,
  Field,
  FlexDivCustom,
  Form,
  StyledTextField,
} from "../styles";
import { formatInputValue } from "../../../../../Utils/formatInputValue";
import { simpleConvertToCents } from "../../../../../Utils/formatters";
import { useToast } from "../../../../../Hooks/toast";
import { useFilter } from "../../../../../Hooks/useFilter";

interface ReceiptFilterProps {
  tableName: string;
  setPageToFirst: () => void;
}

export const ReceiptFilter = ({
  tableName,
  setPageToFirst,
}: ReceiptFilterProps) => {
  const { getFilterObj, handleApplyFilters, handleRemoveFilters } = useFilter();

  const filterObj = getFilterObj(tableName) as any;

  const { addToast } = useToast();

  const [startDate, setStartDate] = useState<Date | null>(() => {
    return filterObj?.start_date !== null &&
      filterObj?.start_date !== undefined &&
      filterObj?.start_date !== ""
      ? new Date(filterObj.start_date.replace(/-/g, "/").replace(/T.+/, ""))
      : new Date();
  });
  const [endDate, setEndDate] = useState<Date | null>(() => {
    return filterObj?.end_date !== null &&
      filterObj?.end_date !== undefined &&
      filterObj?.end_date !== ""
      ? new Date(filterObj.end_date.replace(/-/g, "/").replace(/T.+/, ""))
      : new Date();
  });
  const [startAmount, setStartAmount] = useState<number | null>(null);
  const [endAmount, setEndAmount] = useState<number | null>(null);
  const [externalId, setExternalId] = useState<string>("");
  const [traceId, setTraceId] = useState<string>("");

  const handleCustomStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleCustomEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleDeleteFilters = () => {
    if (Object.keys(filterObj).length === 0) {
      addToast({
        title: "Nenhum filtro aplicado.",
        description: "Não tem filtro aplicado para ser removido.",
        type: "info",
      });
      return;
    }

    handleRemoveFilters(tableName, {
      start_date: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
      end_date: format(new Date(), "yyyy-MM-dd"),
      endToEndId: "",
      start_amount: "",
      end_amount: "",
      external_id: "",
      trace_id: "",
    });
    setStartDate(sub(new Date(), { days: 7 }));
    setEndDate(new Date());
    setStartAmount(null);
    setEndAmount(null);
    setExternalId("");
    setTraceId("");
  };

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();

    const queryParams: any = {
      start_date: startDate === null ? "" : format(startDate, "yyyy-MM-dd"),
      end_date: endDate === null ? "" : format(endDate, "yyyy-MM-dd"),
      start_amount:
        startAmount === null ? "" : simpleConvertToCents(startAmount),
      end_amount: endAmount === null ? "" : simpleConvertToCents(endAmount),
      trace_id: traceId.trim(),
      external_id: externalId.trim(),
    };

    const hasValue = Object.keys(queryParams).some((key) => !!queryParams[key]);

    //verifica se algum filtro foi aplicado
    if (!hasValue) {
      addToast({
        type: "info",
        title: "Nenhum filtro foi aplicado!",
        description: "Por favor insira algum parâmetro.",
      });

      return;
    }

    //Verifica se o filtro ja foi aplicado
    if (JSON.stringify(queryParams) === JSON.stringify(filterObj)) {
      addToast({
        type: "info",
        title: "Filtro já aplicado!",
        description: "Por favor insira outros parâmetros.",
      });

      return;
    }

    //Verifica se as datas estão em um formato válido
    const regex = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/;
    if (
      !regex.test(queryParams.start_date) ||
      isBefore(
        new Date(queryParams.start_date),
        sub(new Date(), { years: 1 })
      ) ||
      isAfter(
        new Date(queryParams.start_date.replace(/-/g, "/").replace(/T.+/, "")),
        new Date()
      ) ||
      !regex.test(queryParams.end_date) ||
      isBefore(
        new Date(queryParams.end_date.replace(/-/g, "/").replace(/T.+/, "")),
        sub(new Date(), { years: 1 })
      ) ||
      isAfter(
        new Date(queryParams.end_date.replace(/-/g, "/").replace(/T.+/, "")),
        new Date()
      )
    ) {
      addToast({
        type: "info",
        title: "Data Inválida!",
        description: "Por favor insira uma data válida.",
      });

      return;
    }

    //Verifica se a data inicial não é maior que a data final
    if (
      isAfter(
        new Date(queryParams.start_date.replace(/-/g, "/").replace(/T.+/, "")),
        new Date(queryParams.end_date.replace(/-/g, "/").replace(/T.+/, ""))
      )
    ) {
      addToast({
        type: "info",
        title: "Data Inválida!",
        description: "Data inicial não pode ser maior que data final.",
      });

      return;
    }

    handleApplyFilters(tableName, queryParams);
    setPageToFirst();
  };

  return (
    <Form onSubmit={handleSubmitForm}>
      <FlexDivCustom>
        <Field>
          <StyledTextField
            id="endToEndId"
            label="Cód. de rastreio"
            value={traceId}
            onChange={(e) => {
              setTraceId(e.target.value);
            }}
            name="endToEndId"
            type="text"
          />
        </Field>
        <Field>
          <StyledTextField
            id="externalId"
            label="External ID"
            value={externalId}
            onChange={(e) => {
              setExternalId(e.target.value);
            }}
            name="externalId"
            type="text"
          />
        </Field>
      </FlexDivCustom>
      <FlexDivCustom>
        <Field>
          <StyledTextField
            id="startAmount"
            label="Valor Min."
            value={startAmount === null ? "" : startAmount}
            onChange={(e) => {
              setStartAmount(formatInputValue(e.target.value));
            }}
            name="startAmount"
            type="text"
            placeholder="Valor (R$)"
            aria-label="minAmount-input"
          />
        </Field>
        <Field>
          <StyledTextField
            id="endAmount"
            variant={"outlined"}
            label={"Valor Máx."}
            value={endAmount === null ? "" : endAmount}
            onChange={(e) => {
              setEndAmount(formatInputValue(e.target.value));
            }}
            name="endAmount"
            type="text"
            placeholder="Valor (R$)"
            aria-label="maxAmount-input"
          />
        </Field>
      </FlexDivCustom>
      <DatePickerContainer>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <Stack
            direction="row"
            style={{ gap: "2.2rem" }}
            alignItems={"center"}
          >
            <Field>
              <DesktopDatePicker
                label="Data inicial"
                value={startDate}
                minDate={sub(new Date(), { years: 1 })}
                maxDate={new Date()}
                onChange={handleCustomStartDateChange}
                renderInput={(params: any) => <DatePickerField {...params} />}
              />
            </Field>
            <Field>
              <DesktopDatePicker
                label="Data final"
                value={endDate}
                minDate={sub(new Date(), { years: 1 })}
                maxDate={new Date()}
                onChange={handleCustomEndDateChange}
                renderInput={(params: any) => <DatePickerField {...params} />}
              />
            </Field>
          </Stack>
        </LocalizationProvider>
      </DatePickerContainer>
      <ButtonsWrapper>
        <button type="submit">Aplicar filtros</button>
        <button type="button" onClick={handleDeleteFilters}>
          Remover filtros
        </button>
      </ButtonsWrapper>
    </Form>
  );
};
