import styled from "styled-components";

export const Container = styled.footer`
  width: 100vw;
  margin: 0 auto;
  padding: 0px;
  height: 100vh;
  background: #fff;
  opacity: 0.8;
  backdrop-filter: blur(2px);
`;
