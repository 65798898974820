import useCallAxios from "./useCallAxios";

const filterQuery = (values) => ({ ...values });

//POST
export const CreatePixQRCode = () => {
  const context = `createPixQRCode`;
  const { reset, requestSuccess, requestData, requestLoading, fetchPOST } =
    useCallAxios({
      context,
    });

  const handlePOST = (params) => {
    fetchPOST({ body: params });
  };

  const state = requestData?.data;

  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handlePOST,
  };
};

//POST
export const InitiatePixPayment = () => {
  const context = `pixPayment`;
  const { reset, requestSuccess, requestData, requestLoading, fetchPOST } =
    useCallAxios({
      context,
    });
  const state = requestData?.data;

  const handlePOST = (params) => {
    fetchPOST({ body: params });
  };

  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handlePOST,
  };
};

//PUT
export const PixPaymentConfirmation = () => {
  const context = `confirmPix`;
  const { reset, requestSuccess, requestData, requestLoading, fetchPUT } =
    useCallAxios({
      context,
    });
  const state = requestData?.data;

  const handlePUT = (params) => {
    const { payload, pix_id } = params;
    fetchPUT({ body: payload, context: `/pix/${pix_id}` });
  };
  //const state = normalizeContactsData(requestData);
  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handlePUT,
  };
};

export const GetPixTransactions = () => {
  const context = `pixList`;
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({ context });
  const state = requestData?.data;
  const handleGET = (params) => {
    fetchGET({ context, queryParameters: filterQuery(params) });
  };

  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handleGET,
  };
};

//DELETE
export const ReturnPix = () => {
  const context = `returnPix`;
  const { reset, requestSuccess, requestData, requestLoading, fetchDELETE } =
    useCallAxios({
      context,
    });
  const state = requestData?.data;
  const handleReturnPix = (params) => {
    fetchDELETE({ context, queryParameters: filterQuery(params) });
  };

  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handleReturnPix,
  };
};
