import { Grid, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "../../Atoms/Button";
import {
  DialogBox,
  Header,
  DigitGrid,
  PasswordContainer,
  DialogContainer,
  DigitButton,
  PasswordHidden,
  ClearButton,
} from "./styles";

export const PasswordConfirm = ({ open, submitPassword, onClose }) => {
  const [firstButton, setFirstButton] = useState();
  const [secondButton, setSecondButton] = useState();
  const [thirdButton, setThirdButton] = useState();
  const [fourthButton, setFourthButton] = useState();
  const [fifthButton, setFifthButton] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [passwordCombination, setPasswordCombination] = useState([]);
  const [passwordHidden, setPasswordHidden] = useState("");

  const setPassword = useCallback(() => {
    submitPassword(passwordCombination);
  }, [passwordCombination]);

  useEffect(() => {
    setIsOpen(open);
    setPasswordCombination([]);
    setPasswordHidden("");
    handleRandomizeNumbers();
  }, [open, onClose]);

  const handleRandomizeNumbers = useCallback(() => {
    let index = 0;
    let randomized = [];
    let numberArray = new Array(10);

    while (index < 10) {
      var randomnumber = Math.floor(Math.random() * 10);
      if (numberArray[randomnumber] != randomnumber) {
        numberArray[randomnumber] = randomnumber;
        index++;
        randomized.push(randomnumber);
      }
    }

    setFirstButton(`${randomized[0]} - ${randomized[1]}`);
    setSecondButton(`${randomized[2]} - ${randomized[3]}`);
    setThirdButton(`${randomized[4]} - ${randomized[5]}`);
    setFourthButton(`${randomized[6]} - ${randomized[7]}`);
    setFifthButton(`${randomized[8]} - ${randomized[9]}`);
  }, []);

  const handleDigitPress = useCallback(
    (value) => {
      if (passwordCombination.length < 4) {
        !!passwordHidden
          ? setPasswordHidden(passwordHidden + "•")
          : setPasswordHidden("•");
        setPasswordCombination((state) => [
          ...state,
          value.replace(/\s+/g, ""),
        ]);
      }
    },
    [passwordCombination]
  );

  return (
    <DialogContainer open={isOpen} keepMounted onClose={onClose}>
      <DialogBox>
        <Header>
          <p>Confirme sua senha transacional de 4 dígitos</p>
          <div style={{ position: "absolute", right: 0 }}>
            <IconButton title="Fechar" onClick={onClose} size="large">
              <Close />
            </IconButton>
          </div>
        </Header>
        <PasswordContainer>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <DigitGrid item xs={4}>
              <DigitButton
                type="button"
                value={firstButton}
                onClick={(e) => handleDigitPress(e.target.value)}
              />
            </DigitGrid>
            <DigitGrid item xs={4}>
              <DigitButton
                type="button"
                value={secondButton}
                onClick={(e) => handleDigitPress(e.target.value)}
              />
            </DigitGrid>
            <DigitGrid item xs={4}>
              <DigitButton
                type="button"
                name="tres"
                value={thirdButton}
                onClick={(e) => handleDigitPress(e.target.value)}
              />
            </DigitGrid>
            {/* </Grid> */}
          </Grid>
          <Grid container>
            <DigitGrid item xs={4}>
              <DigitButton
                type="button"
                name="quatro"
                value={fourthButton}
                onClick={(e) => handleDigitPress(e.target.value)}
              />
            </DigitGrid>
            <DigitGrid item xs={4}>
              <DigitButton
                type="button"
                name="cinco"
                value={fifthButton}
                onClick={(e) => handleDigitPress(e.target.value)}
              />
            </DigitGrid>
            <DigitGrid item xs={4}>
              <ClearButton
                onClick={() => {
                  setPasswordCombination([]);
                  setPasswordHidden([]);
                  handleRandomizeNumbers();
                }}
              >
                limpar
              </ClearButton>
            </DigitGrid>
          </Grid>
          <Grid item xs={12}>
            <PasswordHidden
              placeholder="Insira sua senha"
              id="senha"
              type="text"
              name="senha"
              maxlength="4"
              disabled={true}
              value={passwordHidden}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={setPassword}>Confirmar senha</Button>
          </Grid>
        </PasswordContainer>
      </DialogBox>
    </DialogContainer>
  );
};
