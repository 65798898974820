// import { normalizeTransactionsData } from "./Normalizers";
import useCallAxios from "./useCallAxios";

export const useTransferTransaction = () => {
  const context = `transactionPay`;
  const {
    requestSuccess,
    requestData,
    requestLoading,
    fetchPOST,
    requestMessage,
  } = useCallAxios({
    context,
  });

  const handlePOST = (params) => {
    fetchPOST({ body: params });
  };

  return {
    loading: requestLoading,
    message: requestMessage,
    state: requestData.data,
    success: requestSuccess,
    handlePOST,
  };
};
