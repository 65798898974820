import styled, { css } from "styled-components";
import { lighten } from "polished";

export const Container = styled.div`
  display: flex;
  gap: 10px;
`;

export const Option = styled.input`
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  transition: 0.3s;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.primary.lightest};
  width: 200px;
  height: 50px;
  color: ${(props) => props.theme.primary.lightest};
  font-size: 18px;

  ${(props) =>
    props.isSelected
      ? css`
          background: ${props.theme.primary.lightest} !important;
          color: white !important;
        `
      : css`
          :hover {
            background: ${lighten(0.3, props.theme.primary.lightest)};

            svg {
              color: ${lighten(0.3, props.theme.primary.lightest)};
            }
          }
        `}
`;
