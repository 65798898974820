import intl from "react-intl-universal";
import ptBrJson from "../Locales/pt-BR.json";
import { loadItem } from "./sessionStorage";

const userLanguage = loadItem("userLanguage");

const supportedLocales = ["pt-BR"];

const locales = {
  "pt-BR": ptBrJson,
};

const loadLocales = () => {
  let currentLocale;

  if (userLanguage) {
    currentLocale = userLanguage;
  } else {
    currentLocale = intl.determineLocale({
      urlLocaleKey: "lang",
      cookieLocaleKey: "lang",
    });

    if (!supportedLocales.find((language) => language === currentLocale)) {
      currentLocale = "pt-BR";
    }
  }

  return currentLocale;
};

const updateLocales = (language) => {
  let currentLocale = intl.getInitOptions();
  currentLocale = currentLocale.currentLocale;

  if (!language) {
    currentLocale = intl.determineLocale({
      urlLocaleKey: "lang",
      cookieLocaleKey: "lang",
    });
  }

  if (supportedLocales.find((value) => value === language)) {
    currentLocale = language;
  }

  return currentLocale;
};

export { locales, loadLocales, updateLocales, supportedLocales };
