import {
  Avatar,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

export const DataRow = ({
  title,
  value,
  handleClick = () => {},
  avatar,
  subItem,
}) => {
  const matches = useMediaQuery("(min-width: 1230px)");

  return (
    <ListItem
      style={{
        borderBottom: "1px solid #e0e0e0",
        height: matches ? 65 : "100%",
        padding: 15,
        flexDirection: matches ? "row" : "column",
      }}
    >
      {!!avatar ? (
        <ListItemIcon>
          <Avatar style={{ marginRight: 40 }}>
            {!!value.split(" ")[0] && !!value.split(" ")[1]
              ? value.split(" ")[0].substring(0, 1) +
                value.split(" ")[1].substring(0, 1)
              : value.split(" ")[0].substring(0, 1)}
          </Avatar>
        </ListItemIcon>
      ) : (
        <></>
      )}
      <ListItemText primary={title} />
      <ListItemText primary={value} />
      {!!subItem && <ListItemText secondary={subItem} />}

      <ListItemSecondaryAction onClick={handleClick}>
        <IconButton size="small">
          <ArrowForwardIos />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
