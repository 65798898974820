import { Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { PixCards } from "../PixCards";
import { PaymentsTable } from "../PixTable/PaymentsTable";
import { ReceivedTable } from "../PixTable/ReceivedTable";
import { ScreenGreeting } from "../ScreenGreeting";
import { Container, GreetingContainer } from "./styles";

export const PixDetails = () => {
  const [tabMenuSelected, setTabMenuSelected] = useState(0);

  const handleChangeTabMenu = () => {
    if (tabMenuSelected === 0) {
      setTabMenuSelected(1);
    } else {
      setTabMenuSelected(0);
    }
  };

  return (
    <Container>
      <GreetingContainer>
        <ScreenGreeting
          title={"Detalhes das suas transações PIX"}
          subTitle={
            "Aqui você poderá visualizar todos os detalhes de suas transações PIX"
          }
        />
      </GreetingContainer>
      <PixCards />
      <Tabs
        value={tabMenuSelected}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTabMenu}
      >
        <Tab label="Pagamentos" style={{ fontWeight: "bold" }} />
        <Tab label="Recebimentos" style={{ fontWeight: "bold" }} />
      </Tabs>

      {tabMenuSelected === 0 && <PaymentsTable />}
      {tabMenuSelected === 1 && <ReceivedTable />}
    </Container>
  );
};
