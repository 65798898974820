import { ListItem } from "@mui/material";
import styled, { css } from "styled-components";

interface IProps {
  isSelected: boolean;
}

export const Container = styled.div`
  height: 100%;
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  overflow-y: hidden;

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  button {
    margin-top: 20px;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  margin: 20px;
  width: 100%;
  font-size: 10px;
  justify-content: center;
  align-items: center;
`;

export const ListItemContent = styled(ListItem)<IProps>`
  ${(props) =>
    props.isSelected &&
    css`
        background: ${props.theme.primary.light};
        border-radius: 4px;
      
        p,span{
          color:white;
        }
      }
    `};
  cursor: pointer;
`;
