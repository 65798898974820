import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";

import {
  CircularProgressWithoutBorder,
  Container,
  GridComponent,
} from "./styles";
import Skeleton from "@mui/lab/Skeleton";
import { useState } from "react";
import { Link } from "react-router-dom";
import { CardButton } from "../../Atoms";

import SyncAltIcon from "@mui/icons-material/SyncAlt";
import {
  DoubleArrow,
  AttachMoney,
  Visibility,
  VisibilityOff,
  Replay,
} from "@mui/icons-material";

import { formatCurrency } from "../../../Utils/formatters";

const DesktopBalance = ({
  accountBalanceData,
  handleOptions,
  refreshBalance,
  loadingRefreshBalance,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [visibilityData, setVisibilityData] = useState(false);
  const [method, setMethod] = useState({});
  const handleChangeVisibilityData = () => setVisibilityData(!visibilityData);

  return (
    <Container>
      <CardContent>
        <GridComponent container spacing={1} alignContent="space-between">
          <GridComponent
            className="balance"
            container
            direction="row"
            alignContent="center"
            xs={12}
          >
            <GridComponent item xs={8}>
              <Typography color="secondary" paragraph>
                SALDO DISPONÍVEL
              </Typography>
            </GridComponent>
            <GridComponent item xs={2}>
              <button
                aria-label="show account balance"
                style={{ background: "none", border: "none" }}
                onClick={handleChangeVisibilityData}
              >
                {visibilityData ? <Visibility /> : <VisibilityOff />}
              </button>
            </GridComponent>
          </GridComponent>
          {/* TODO - Inserir cor azul de forma correta*/}
          <GridComponent item xs={6}>
            <Typography variant="h5" gutterBottom>
              {visibilityData && !loadingRefreshBalance.GET ? (
                formatCurrency(accountBalanceData)
              ) : loadingRefreshBalance.GET ? (
                <>
                  <CircularProgressWithoutBorder size={30} />
                </>
              ) : (
                <Skeleton animation={false} />
              )}
            </Typography>
          </GridComponent>
          <GridComponent item xs={6} style={{ textAlign: "end" }}>
            {visibilityData && (
              <button
                aria-label="refresh account balance"
                onClick={() => refreshBalance()}
                style={{ background: "none", border: "none" }}
              >
                <Replay style={{ border: "none" }} />
              </button>
            )}
          </GridComponent>
        </GridComponent>
      </CardContent>
      <CardActions style={{ justifyContent: "center" }}>
        <Link
          to={{
            pathname: "/boletos",
            search: "newTicket=true",
          }}
        >
          <CardButton icon={<AttachMoney />}>RECEBER</CardButton>
        </Link>
        <Link to="/pagamento">
          <CardButton icon={<DoubleArrow />}>PAGAR</CardButton>
        </Link>
        <Link
          to={{
            pathname: "/transferencia",
            search: "newTransfer=true",
          }}
        >
          <CardButton icon={<SyncAltIcon />}>TRANSFERIR</CardButton>
        </Link>
      </CardActions>
    </Container>
  );
};

export default DesktopBalance;
