import styled, { css } from "styled-components";
import { TableHead, Grid, TableRow, IconButton } from "@mui/material";

interface ActiveStatusProps {
  isActive?: boolean;
  filterIsActive?: boolean;
}

interface ExpandMoreButtonProps {
  expand: boolean;
}
interface StatusTableCellProps {
  status: "paid" | "pending" | "refused" | "canceled";
}

export const HeaderTable = styled(TableHead)`
  && {
    border-radius: 0.3rem;
    th {
      font-weight: bold;
      text-align: center;
    }
  }
`;

export const StyledGridContainer = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const StyledGrid = styled(Grid)`
  && {
    margin-top: 2rem;
    position: relative;
    z-index: 0;

    width: 100%;
  }
`;

export const StatusTableCell = styled.p<StatusTableCellProps>`
  padding: 0.5rem 0.2rem;
  color: white;
  font-weight: bold;

  border-radius: 0.2rem;
  ${(props) => {
    let style = "" as any;
    switch (props.status) {
      case "paid":
        style = css`
          background: #4fa84f;
        `;
        break;
      case "pending":
        style = css`
          background: #4b4bff; ;
        `;
        break;
      case "refused":
        style = css`
          color: #5b5b5b;
          background: #c7c7c7;
        `;
        break;
      case "canceled":
        style = css`
          background: red;
        `;
        break;
    }
    return style;
  }}
`;

export const Container = styled.div<ActiveStatusProps>`
  position: relative;
  z-index: 1;

  margin-left: 0;
  min-height: 1rem;
  transition: margin 700ms;

  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    width: 100% !important;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: start;
  gap: 6rem;
`;

export const TerminalsStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    width: 70px;
    padding: 0.3rem;

    border: 2px solid;
    border-radius: 0.3rem;

    .quantity {
      font-weight: bold;
      font-size: 1.2em;
    }
  }
  .total {
    border-color: ${(props) => props.theme.background.dark};
  }

  .active {
    border-color: green;
  }

  .deactivated {
    border-color: red;
  }
`;

export const ActiveStatus = styled.span<ActiveStatusProps>`
  display: flex;
  align-items: center;

  gap: 0.5rem;

  font-weight: bold;
  font-size: 0.9em;
  svg {
    font-size: 1.2em;
  }

  ${(props) =>
    props.isActive
      ? css`
          color: ${(props) => props.theme.feedback.success};
        `
      : css`
          color: ${(props) => props.theme.feedback.error};
        `}
`;

export const CustomTableRow = styled(TableRow)`
  && {
    border-bottom: 2px solid #cecece;

    td,
    span {
      text-align: center;
      color: #393939;
    }
  }
`;

export const ExpandMoreButton = styled(IconButton)<ExpandMoreButtonProps>`
  ${(props) =>
    props.expand
      ? css`
          transform: rotate(180deg);
        `
      : css`
          transform: rotate(0deg);
        `}
`;

export const StyledH2 = styled.h2`
  font-size: 1.2em;
  color: #717171;

  margin-bottom: 1rem;
`;

export const CollapseContainer = styled.div`
  padding: 1rem 5rem;
`;

export const CollapseContent = styled.div`
  display: flex;
  align-items: center;
  gap: 15rem;

  p {
    display: flex;
    flex-direction: column;

    color: gray;

    font-size: 1.1em;

    span {
      color: #393939;
    }
  }
`;

export const TransactionsNotFoundWarn = styled.p`
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.background.dark};
`;

export const IDsContainer = styled.div`
  span {
    display: inline-block;
    width: 340px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
