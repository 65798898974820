import styled from "styled-components";
import Tooltip from "../../Atoms/Tooltip";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-self: flex-start;
  box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 12%);
`;

export const ReceiptHeader = styled.div`
  background: #2f3e9e;
  color: #2f3e9e;
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
  }
`;

export const ReceiptTitle = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    text-align: center;

    h2,
    h3,
    h4 {
      margin-bottom: 20px;
    }
    h2 {
      color: #2f3e9e;
      font-size: 48px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 32px;
    }
    h3 {
      color: #2f3e9e;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 32px;
    }
    h4 {
      color: #747474;
      font-style: normal;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 16px;
    }
  }
  hr {
    border: 2px solid #f2f2f2;
    height: 2px;
    background: #f2f2f2;
    width: 100%;
  }
`;

export const ReceiptDetailsContent = styled.div`
  div {
    margin: 10px;
    padding-left: 15px;

    h4 {
      font-size: 16px;
      font-weight: 700;
      color: #444;
    }

    h4,
    span,
    p {
      margin-bottom: 5px;
      text-align: left;
    }

    p {
      font-size: 20px;
      color: #00559a;
      font-weight: 700;
    }
    span {
      color: #999;
      font-size: 14px;
    }
  }
`;

export const ReceiptFooter = styled.div`
  display: flex;
  margin: 10px;
  padding-left: 15px;
  flex-direction: column;
  hr {
    border: 1px solid #f2f2f2;
    height: 2px;
    width: 100%;
    margin-bottom: 20px;
  }
  p {
    color: #999;
    font-size: 14px;
  }
`;
export const HelpTooltip = styled(Tooltip)`
  font-size: 25px;
  color: #c0c0c0;
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.primary.light};
    transition: 1s;
  }
`;

export const ReceiptValue = styled.div`
  width: 100%;
  justify-content: center;
  gap: 20px;
  display: flex;
  flex-direction: row;
`;
