import React, { createContext, useContext, useCallback, useState } from "react";
import { useLogin } from "./Auth";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

interface AuthState {
  token: string;
}

interface CaptchaContextData {
  // captchaToken: string;
  loading: boolean;
  setCaptcha(obj: any): any;
}

const CaptchaContext = createContext<CaptchaContextData>(
  {} as CaptchaContextData
);

const CaptchaProvider: React.FC = ({ children }) => {
  const { fetchLogin, requestLoading } = useLogin();

  const setCaptcha = useCallback((obj) => {
    fetchLogin({
      type: obj?.type,
      username: obj.username,
      password: obj.password,
    });
  }, []);

  return (
    <CaptchaContext.Provider value={{ setCaptcha, loading: requestLoading }}>
      {children}
    </CaptchaContext.Provider>
  );
};

function useCaptcha() {
  const context = useContext(CaptchaContext);
  if (!context) {
    throw new Error("useCaptcha must be used within a Captcha provider");
  }
  return context;
}

export { CaptchaProvider, useCaptcha };
