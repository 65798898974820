import { ScreenGreeting } from "../../../../Components/Molecules";
import {
  Container,
  Header,
  Content,
  CopyAndPastPixContent,
  // TextArea,
  // QRCodeDescription,
  FormControlLabelComponent,
  // RadioGroupComponent,
  // Label,
} from "./styles";
import { useCallback, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import {
  Button,
  ConfirmDialogModal,
  // TextArea,
} from "../../../../Components/Atoms";
import getValidationErrors from "../../../../Utils/getValidationErrors";
import { useHistory } from "react-router";
import {
  ArrowBack,
  CheckCircle,
  // FileCopy
} from "@mui/icons-material";
// import { PixPreview } from "../../../../Components/Molecules";
import { Checkbox, Typography } from "@mui/material";
import { InputMoney } from "../../../../Components/Atoms/InputMoney";
import { useToast } from "../../../../Hooks/toast";
import {
  convertToCents,
  formatCurrency,
  stringToDecimal,
} from "../../../../Utils/formatters";
import { CreatePixQRCode } from "../../../../Hooks/UsePix";
import { ModalLoader } from "../../../../Components/Atoms/ModalLoader";
import { RiWhatsappLine } from "react-icons/ri";

export const PixSales = () => {
  const {
    handlePOST: fetchCreateQrCode,
    loading,
    state,
    success,
  } = CreatePixQRCode();
  const { addToast } = useToast();
  const linkRef = useRef(null);
  const formRef = useRef(null);
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [qrCodeType, setQrCodeType] = useState("static");
  const [isUndefinedValue, setIsUndefinedValue] = useState(false);
  const [linkValue, setLinkValue] = useState(0);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const [whatsAppUrl, setWhatsAppUrl] = useState("");

  const resetStates = useCallback(() => {
    setLinkValue(0);
    setStep(0);
  }, [step, linkValue]);

  const copyToClipboard = useCallback(() => {
    try {
      if (linkRef.current) linkRef.current.select();
      document.execCommand("copy");
      addToast({
        title: "Linha copiada!",
        type: "info",
      });
    } catch (err) {
      console.log(err);
    }
  }, [addToast]);

  const handleConfirmQrCodeCreation = useCallback(
    (value) => {
      if (value === true) {
        setOpenModalConfirmation(!openModalConfirmation);
        fetchCreateQrCode({
          amount: linkValue,
          description: "string",
        });
      }
    },
    [openModalConfirmation]
  );

  const handleSubmitValue = useCallback(
    async (data) => {
      if (!isUndefinedValue) {
        const amount = convertToCents(data.value);
        data.value = stringToDecimal(data.value);

        const schema = Yup.object().shape({
          value: Yup.number()
            .typeError("Digite o valor do link")
            .required("Digite o valor do link")
            .moreThan(0, "Valor deve ser maior que R$ 0.00"),
        });
        try {
          await schema.validate(data, {
            abortEarly: false,
          });
          setLinkValue(amount);
          setOpenModalConfirmation(true);
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);
            formRef.current?.setErrors(errors);
            return;
          }
        }
      } else {
        setOpenModalConfirmation(true);
      }
    },
    [openModalConfirmation, isUndefinedValue]
  );

  useEffect(() => {
    if (success.POST && !!state) {
      let wppUrl = "";
      if (state.amount <= 0) {
        wppUrl = `https://api.whatsapp.com/send?&text=Para me transferir um valor à sua escolha através de sua conta, copie o código pix abaixo e o insira em pix copia e cola na área pix em seu App.\n\n*${state.qrcode}*`;
      } else if (state.amount > 0) {
        wppUrl = `https://api.whatsapp.com/send?&text=Para me transferir ${state.amount/100} através de sua conta, copie o código pix abaixo e o insira em pix copia e cola na área pix em seu App.\n\n*${state.qrcode}*`;
      }

      wppUrl = encodeURI(wppUrl);
      setWhatsAppUrl(wppUrl);

      setStep(1);
    }
  }, [success, state]);

  // const handleSubmitQrCodeOption = useCallback(() => {
  //   try {
  //     if (!qrCodeType) {
  //       addToast({
  //         title: "Selecione uma opção antes de prosseguir",
  //         type: "error",
  //       });
  //       return;
  //     }
  //     setStep(1);
  //   } catch (err) {
  //     if (err instanceof Yup.ValidationError) {
  //       const errors = getValidationErrors(err);
  //       formRef.current?.setErrors(errors);
  //       return;
  //     }
  //   }
  // }, [qrCodeType]);

  // const step0 = (
  //   <>
  //     <ScreenGreeting
  //       title={"Receber por Pix"}
  //       subTitle={"Gere um QrCode e faça suas vendas pelo PIX"}
  //     />
  //     <Content>
  //       <Header>
  //         <h6>Selecione o tipo de venda</h6>
  //         <p
  //           onClick={() => {
  //             history.push("/pix");
  //           }}
  //         >
  //           <ArrowBack />
  //           Voltar
  //         </p>
  //       </Header>
  //       <Form type="submit" onSubmit={handleSubmitQrCodeOption} ref={formRef}>
  //         <QRCodeDescription>
  //           <RadioGroupComponent
  //             defaultValue="female"
  //             name="radio-buttons-group"
  //           >
  //             <section>
  //               <h5>QR Code Estático</h5>
  //               <p>Para vendas simples.</p>
  //               <ul>
  //                 <li>Pode receber mais de um pagamento</li>
  //                 <li>Pode ou não ter um valor definido</li>
  //                 <li>É gratuito</li>
  //               </ul>

  //               <FormControlLabelComponent
  //                 value={"static"}
  //                 control={<Radio color={"primary"} />}
  //                 label="Qr Code estático"
  //                 onChange={() => setQrCodeType("static")}
  //               />
  //             </section>
  //             <section>
  //               <h5>QR Code Dinâmico</h5>
  //               <p>Para quem quer mais controle sobre as vendas</p>
  //               <ul>
  //                 <li>Pode receber apenas um pagamento.</li>
  //                 <li>Deve ter um valor definido.</li>
  //                 <li>
  //                   Uma tarifa de 1.89% é cobrada por cada pagamento recebido.
  //                 </li>
  //               </ul>
  //               <FormControlLabelComponent
  //                 value={"dinamic"}
  //                 control={<Radio color={"primary"} />}
  //                 label="Qr Code dinâmico"
  //                 onChange={() => setQrCodeType("dinamic")}
  //               />
  //             </section>
  //           </RadioGroupComponent>
  //         </QRCodeDescription>
  //         <Button type="submit">Continuar</Button>
  //       </Form>
  //     </Content>
  //   </>
  // );

  const step0 = (
    <>
      <ScreenGreeting
        title={"Receber por Pix"}
        subTitle={"Gere um QrCode e faça suas vendas pelo PIX"}
      />
      <Content>
        <Header>
          <h6>Quanto você deseja cobrar?</h6>
          <p
            onClick={() => {
              history.push("/pix");
            }}
          >
            <ArrowBack />
            Voltar
          </p>
        </Header>
        <Form type="submit" onSubmit={handleSubmitValue} ref={formRef}>
          <div>
            {qrCodeType === "static" && (
              <FormControlLabelComponent
                style={{ width: "100%", marginBottom: 10 }}
                control={
                  <Checkbox
                    color="primary"
                    value={isUndefinedValue}
                    onChange={() => setIsUndefinedValue(!isUndefinedValue)}
                  />
                }
                label="Cobrança sem valor definido"
              />
            )}

            {!isUndefinedValue && <InputMoney />}
          </div>
          <Button type="submit" loading={loading.POST}>
            Continuar
          </Button>
        </Form>
      </Content>
    </>
  );

  const stepFinalSuccess = (
    <>
      <ScreenGreeting
        title={"Receber por Pix"}
        subTitle={"Gere um QrCode e faça suas vendas pelo PIX"}
      />
      <Content>
        <Header>
          <h6>Compartilhe seu código pix</h6>
          <p
            onClick={() => {
              history.push("/pix");
              resetStates();
            }}
          >
            <ArrowBack />
            Voltar
          </p>
        </Header>

        <CopyAndPastPixContent>
          <Typography variant="h5">
            Pix copia e cola emitido com sucesso!
            <CheckCircle
              style={{
                verticalAlign: "sub",
                color: "green",
                marginLeft: 10,
                fontSize: 30,
              }}
            />
          </Typography>
          {state?.amount === 0 ? (
            <span>
              Código copia e cola sem valor definido. <br />
              Copie e o envie para quem vai realizar a transferência.
            </span>
          ) : (
            <span>
              Código copia e cola: {formatCurrency(state?.amount)}. <br />
              Copie ou compartilhe para quem deseja realizar a cobrança.
            </span>
          )}

          <textarea ref={linkRef} value={state?.qrcode} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              width: "100%",
            }}
          >
            <Button
              onClick={() => {
                copyToClipboard(linkRef);
              }}
            >
              Copiar código pix
            </Button>

            <Button
              action="whatsapp"
              onClick={() => {
                window.open(whatsAppUrl);
              }}
            >
              Enviar por whatsapp{" "}
              <RiWhatsappLine
                style={{ verticalAlign: "middle", fontSize: 18 }}
              />
            </Button>
          </div>
        </CopyAndPastPixContent>
      </Content>
    </>
  );

  return (
    <Container>
      <ModalLoader open={loading.POST} text={"Gerando cobrança"} />
      <ConfirmDialogModal
        open={openModalConfirmation}
        onClose={() => setOpenModalConfirmation(false)}
        text={
          isUndefinedValue
            ? `Deseja criar a cobrança pix sem valor definido?`
            : `Deseja criar a cobrança pix no valor de ${formatCurrency(
                linkValue
              )} `
        }
        description={"Para criar clique em confirmar."}
        refreshAction={(value) => handleConfirmQrCodeCreation(value)}
      />
      {step === 0 && step0}
      {step === 1 && stepFinalSuccess}
    </Container>
  );
};
