import useCallAxios from "./useCallAxios";

const filterQuery = (values) => ({ ...values });

export const useReportExcel = () => {
  const context = `excelStatement`;

  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({ context });

  const handleGET = (params) => {
    fetchGET({
      queryParameters: filterQuery(params),
      responseType: "blob",
    });
  };

  return {
    reset,
    loading: requestLoading,
    state: requestData,
    success: requestSuccess,
    handleGET,
  };
};
export const useReportCSV = () => {
  const context = `csvStatement`;
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({ context });

  const handleGET = (params) => {
    fetchGET({
      queryParameters: filterQuery(params),
      responseType: "blob",
    });
  };

  return {
    reset,
    loading: requestLoading,
    state: requestData,
    success: requestSuccess,
    handleGET,
  };
};
