import { loadItem } from "../../../Utils";

export const CompanyLogo = ({ width, height, isWhiteLogo = false }) => {
  const { logo_default, logo_white } = loadItem("Options");

  return !!logo_default || !!logo_white ? (
    <img
      src={isWhiteLogo ? logo_white : logo_default}
      alt="Logo"
      width={!!width ? width : 210}
      height={!!height ? height : 70}
    />
  ) : (
    <></>
  );
};

CompanyLogo.propTypes = {};
