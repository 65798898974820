import React, { useContext, useEffect, useState } from "react";
import intl from "react-intl-universal";
import { UserContext } from "../Contexts/User";
import { locales } from "../Utils";

const Translations = ({ children }) => {
  const [stateUserContext] = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let currentLanguage = intl.getInitOptions();
    currentLanguage = currentLanguage.currentLocale;
    let newLanguage = "pt-BR";

    if (currentLanguage === stateUserContext.userLanguage) {
      return;
    }

    if (window.navigator.language && locales[window.navigator.language]) {
      newLanguage = window.navigator.language;
    }

    if (stateUserContext.userLanguage) {
      newLanguage = stateUserContext?.userLanguage;
    }

    intl
      .init({
        locales,
        fallbackLocale: "pt-BR",
        currentLocale: newLanguage,
      })
      .then(() => {
        setLoaded(true);
      });
  }, [stateUserContext]);

  return <>{loaded ? children : <></>}</>;
};

export default Translations;
