import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 60px;

  h4 {
    text-align: center;
    font-size: 30px;
  }

  p {
    margin-top: 40px;
    width: fit-content;
    color: #777;
    cursor: pointer;

    svg {
      margin-right: 5;
      vertical-align: middle;
      font-size: 20px;

      :hover {
        transition: 1s;
      }
    }
  }
`;

export const Content = styled.div`
  min-height: 40%;
  padding-top: 40;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
