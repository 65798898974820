import { format } from "date-fns";
import { translateStatusValues } from "./checkObjectProperties";
import { formatCurrency } from "./formatters";

export function ConvertObjectTranslatedToArray(ObjectToBeTransformed) {
  let array = [];

  Object.keys(ObjectToBeTransformed).forEach((key) => {
    if (ObjectToBeTransformed[key] === "") {
      return;
    }

    switch (key) {
      //FILTRO SALES
      case "endToEndId":
        array.push({
          "Cód. de rastreio": ObjectToBeTransformed[key],
          [key]: "",
        });
        break;
      case "trace_id":
        array.push({
          "Cód. de rastreio": ObjectToBeTransformed[key],
          [key]: "",
        });
        break;
      case "startAmount":
        array.push({
          "Valor min.": formatCurrency(ObjectToBeTransformed[key]),
          [key]: "",
        });
        break;
      case "endAmount":
        array.push({
          "Valor máx.": formatCurrency(ObjectToBeTransformed[key]),
          [key]: "",
        });
        break;
      case "startDate":
        array.push({
          "Data inicial": format(
            new Date(
              ObjectToBeTransformed[key].replace(/-/g, "/").replace(/T.+/, "")
            ),
            "dd/MM/yyyy"
          ),
          [key]: "",
        });
        break;
      case "endDate":
        array.push({
          "Data final": format(
            new Date(
              ObjectToBeTransformed[key].replace(/-/g, "/").replace(/T.+/, "")
            ),
            "dd/MM/yyyy"
          ),
          [key]: "",
        });
        break;
      case "start_date":
        array.push({
          "Data inicial": format(
            new Date(
              ObjectToBeTransformed[key].replace(/-/g, "/").replace(/T.+/, "")
            ),
            "dd/MM/yyyy"
          ),
          [key]: "",
        });
        break;
      case "end_date":
        array.push({
          "Data final": format(
            new Date(
              ObjectToBeTransformed[key].replace(/-/g, "/").replace(/T.+/, "")
            ),
            "dd/MM/yyyy"
          ),
          [key]: "",
        });
        break;
      case "id":
        array.push({
          "Identificador PIX": ObjectToBeTransformed[key],
          [key]: "",
        });
        break;
      case "id":
        array.push({
          "Identificador PIX": ObjectToBeTransformed[key],
          [key]: "",
        });
        break;

      case "externalId":
        array.push({ "External ID": ObjectToBeTransformed[key], [key]: "" });
        break;
      case "external_id":
        array.push({ "External ID": ObjectToBeTransformed[key], [key]: "" });
        break;
      case "name":
        array.push({ Pagador: ObjectToBeTransformed[key], [key]: "" });
        break;
      case "document":
        array.push({ Documento: ObjectToBeTransformed[key], [key]: "" });
        break;
      case "status":
        array.push({
          Status: translateStatusValues(ObjectToBeTransformed[key]),
          [key]: "",
        });
        break;

      //Filtro de Boletos
      case "due_date_start":
        array.push({
          "Data inicial de vencimento": format(
            new Date(
              ObjectToBeTransformed[key].replace(/-/g, "/").replace(/T.+/, "")
            ),
            "dd/MM/yyyy"
          ),
          [key]: "",
        });
        break;
      case "due_date_end":
        array.push({
          "Data final de vencimento": format(
            new Date(
              ObjectToBeTransformed[key].replace(/-/g, "/").replace(/T.+/, "")
            ),
            "dd/MM/yyyy"
          ),
          [key]: "",
        });
        break;
      case "type":
        array.push({
          Status: translateStatusValues(ObjectToBeTransformed[key]),
          [key]: "",
        });
        break;
      case "start_amount":
        array.push({
          "Valor min.": formatCurrency(ObjectToBeTransformed[key]),
          [key]: "",
        });
        break;
      case "end_amount":
        array.push({
          "Valor máx.": formatCurrency(ObjectToBeTransformed[key]),
          [key]: "",
        });
        break;
      default:
        "";
    }
  });
  return array;
}
