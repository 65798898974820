import { differenceInDays, format, sub } from "date-fns";

export const comparingDates = (startDate, endDate) => {
  const endDateFormatted = format(
    new Date(endDate.replace(/-/g, "/").replace(/T.+/, "")),
    "yyyy-MM-dd"
  );

  const datesRange = {
    day7: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
    day15: format(sub(new Date(), { days: 15 }), "yyyy-MM-dd"),
    day30: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
    day60: format(sub(new Date(), { days: 60 }), "yyyy-MM-dd"),
    day90: format(sub(new Date(), { days: 90 }), "yyyy-MM-dd"),
    day120: format(sub(new Date(), { days: 120 }), "yyyy-MM-dd"),
    day180: format(sub(new Date(), { days: 180 }), "yyyy-MM-dd"),
  };

  const dates = Object.values(datesRange);

  let selectableDate = 0;
  dates.forEach((date) => {
    if (
      startDate === date &&
      endDateFormatted === format(new Date(), "yyyy-MM-dd")
    ) {
      selectableDate = differenceInDays(
        new Date(endDate.replace(/-/g, "/").replace(/T.+/, "")),
        new Date(startDate.replace(/-/g, "/").replace(/T.+/, ""))
      );
    }
  });

  return selectableDate;
};
